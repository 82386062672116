import { onValue, ref } from 'firebase/database';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { db } from '../../../../firebase';
import ReactStars from "react-rating-stars-component";
import { CommonContextData } from '../../../Context/CommonContext';

const AstrologersProfile = () => {
    const { loginModal, setLoginModal, astrologin, setastrologin } = useContext(CommonContextData);

    const UserIddata = JSON.parse(sessionStorage.getItem('UserProfile'));
    const [UserId, SetUserId] = useState(UserIddata?.data?.data?.id)
    const [Status, SetStatus] = useState(false)
    const [filternamedata, setfilternamedata] = useState([]);

    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    const query = useQuery();
    const Iddata = query?.get("AP");

    const handleCallClick = () => {
        window.open("https://play.google.com/store/apps/details?id=app.arustu.astro4call&pcampaignid=web_share", '_blank');
    };

    const fetchData = async () => {
        const dbRef = ref(db, 'Astrologers');
        onValue(dbRef, (snapshot) => {
            let records = [];
            snapshot.forEach(childSnapshot => {
                let keyName = childSnapshot.key;
                let data = childSnapshot.val();
                records.push({ "id": keyName, "data": data });
            });
            setfilternamedata(records.filter(item => item.data.id === Iddata));
        });
    };

    useEffect(() => {
        fetchData();
    }, [Iddata]);

    if (!filternamedata.length) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container ">
            <div>
                <ul className="breadcrumbs-two" > 
                    <li>
                        <Link to={'/'}>
                            <i className="fa fa-home px-1"></i>
                        </Link>
                    </li>
                    <p className='pro-div'>
                        <div>{filternamedata[0]?.data?.name} Profile</div>
                    </p>
                </ul>
            </div>

            {filternamedata?.map((item) => (
                <div key={item.id} className="border_outline">
                    <div className="row align-items-start bb">
                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4">
                            <div className="astrloger_profile_picture">
                                <div className="astrolger_profile_background">
                                    <img height="228" width="228" className="profile_img" alt={item?.data?.name}
                                        src={item?.data?.profile}
                                    />
                                    <ReactStars
                                        value={item?.data?.star_count}
                                        isHalf={true}
                                        count={5}
                                        size={40}
                                        activeColor="#ffd700"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-9 col-lg-9 col-md-8 col-sm-8">
                            <h6>
                                <div className={`spinner-grow spinner-grow-sm me-1 
                                                    ${item?.data?.status === 'busy' ? "asdas" : (item?.data?.isOnlineAudio || item?.data?.isOnlineChat) ? "text-success" : "text-danger"}`}
                                    role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <span style={{ fontSize: '25px', fontFamily: 'sans-serif' }}> {item?.data?.name}</span>
                            </h6>

                            <div className="profession_astrologer mx-4">{item?.data?.graduation}</div>
                            <div className="language_astrolger mx-4">{item?.data.languages}</div>
                            <div className="experience_astrologer mx-4">
                                <i className="fa fa-graduation-cap"></i> Exp: {item?.data?.exp_year} Years
                            </div>
                            <div className="fess_amnt mx-4">
                                <span><strong>₹ {item?.data?.rate}</strong>/min</span>
                                <div style={{ color: '#e76363', fontSize: '15px' }}>
                                    {(item?.data?.isOnlineAudio || item?.data?.isOnlineChat) === false &&
                                        <span>Again Login : {item?.data?.nxtOnlineDate} {item?.data?.nxtOnlineTime}</span>
                                    }
                                </div>
                            </div>
                            <div className="row btns_chat_call">
                                {(item?.data?.isOnlineAudio) && (
                                    <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-6 col-sm-6 display-grid">
                                        <button className="c-btn"
                                            style={{ display: 'flex', padding: '20px', margin: '20px', height: '60px', gap: '10px' }}
                                            onClick={() => { UserId ? SetStatus(true) : setLoginModal(true) }}>
                                            <img height={25} width={22} alt="calling"
                                                src="https://aws.astrotalk.com/assets/images/chatoffline/online-status-chat.webp"
                                            />
                                            <div className="strt"> Start Chat </div>
                                        </button>
                                    </div>
                                )}
                                {(item?.data?.isOnlineChat) && (
                                    <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-6 col-sm-6 display-grid">
                                        <button className="c-btn"
                                            style={{ display: 'flex', padding: '20px', margin: '20px', height: '60px', gap: '10px' }}
                                            onClick={() => {
                                                UserId ? SetStatus(true) : setLoginModal(true)
                                            }}>
                                            <img width={25} height={22} alt="callOnline"
                                                src="https://aws.astrotalk.com/assets/images/chatoffline/online-status-call.webp"
                                            />
                                            <div className="start_btn">
                                                <div className="strt">Start Call</div>
                                            </div>
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-12">
                            <div className="aboutme_left">
                                <h4 className="about_me_heading text-center text-primary">About me</h4>
                                <p className="about_me_parah">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially  versions of Lorem Ipsum.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            {
                Status &&
                <div id="c-modal">
                    <div className="c-modal-body py-3">
                        <div className="c-modal-header d-flex justify-content-between px-2">
                            <h2>Download</h2>
                            <h4 onClick={() => SetStatus(false)} style={{ cursor: "pointer" }}><i className='fa fa-times'></i></h4>
                        </div>
                        <h5>Are You Want To Download ?</h5>
                        <div>
                            <button type='button' className='c-btn' onClick={() => { SetStatus(false); handleCallClick() }} >download</button>
                            <button className='c-btn' onClick={() => { SetStatus(false) }} >Cancel</button>
                        </div>

                    </div>
                </div>
            }
        </div>
    );
};

export default AstrologersProfile;
