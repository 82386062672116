import axios from 'axios';
import { Item } from 'devextreme-react/cjs/accordion';
import React, { useContext, useEffect, useState } from 'react'
import { CommonContextData } from '../../../Context/CommonContext';
import { useLocation } from 'react-router-dom';



const Purchase = () => {

  const ordersdata = JSON.parse(sessionStorage.getItem('UserProfile'));
  const { loginModal, setLoginModal, astrologin, setastrologin } = useContext(CommonContextData);

  const useQuery = () => {
    const params = new URLSearchParams(useLocation().search);
    return {
      get: (param) => params.get(param)
    };
  };

  const UserIddata = JSON.parse(sessionStorage.getItem('UserProfile'));
  const [UserId, SetUserId] = useState(UserIddata?.data?.data?.id)

  const query = useQuery();
  var Productinfodata = query?.get("PR");
  const str = sessionStorage.getItem('FilterData');
  const parsedObject = JSON.parse(str);

  const [value, setValue] = useState({ id: '', productinfo: '', amount: '', extra_amount: '', message: '', day: '', gst: '', })
  const [FilterData, SetFilterData] = useState()

  useEffect(() => {
    Purchaseproduct();
  }, []);

  const Purchaseproduct = async (e) => {
    try {
      const { id, productinfo, amount, extra_amount, message, day, gst, } = value;
      const val = { 'id': id, 'productinfo': productinfo, 'amount': amount, 'extra_amount': extra_amount, 'message': message, 'day': day, 'gst': gst }
      const res = await axios.get('https://astrocall.live/api/product', val);

      if (res?.data?.data) {
        SetFilterData(res?.data?.data?.filter((item) => item.productinfo === Productinfodata));
        sessionStorage.setItem('FilterData', JSON.stringify(res?.data?.data?.filter((item) => item.productinfo === Productinfodata)));
      }
    } catch (error) {
      console.log(error, 'error')
    }
  };


  const loadRazorpay = async () => {
    var options = {
      "key": "rzp_live_zWtXPYpzqDLpAH",
      "amount": TotalAmount * 100,
      // "currency": "INR", 
      // "name": "NiceSnippets",
      // "description": "Test Transaction",
      // "image": "https://www.nicesnippets.com/image/imgpsh_fullsize.png",
      // "order_id": ordersdata.user_id,
      handler: async function (response) {
        // console.log(response, 'response')
      },
    }
    // console.log(options, 'options')
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  }

  // const User_Payment = async () => {
  //   try {
  //     const val = { 'user_id': LoginUserId, 'mihpayid': mihpayid, 'first_name': first_name, 'status': status, 'payment_amount': payment_amount, 'payment_date': payment_date, 'minutes': minutes, 'txnid': txnid, 'productinfo': productinfo, 'name_on_card': name_on_card, 'cardnum': cardnum, 'mode': mode, 'issuing_bank': issuing_bank, 'reference_no': reference_no, 'order_id': order_id }
  //     console.log(val, 'val')
  //     const res = await axios.post('https://astrocall.live/api/payment', val);
  //     console.log(res, 'res')
  //   } catch (error) {
  //     // Handle error
  //   }
  // }


  // useEffect(() => {
  //   User_Payment()
  // })


  const gst = (FilterData ? (((parseInt(FilterData[0]?.amount)) * 18) / 100) : <></>)
  const TotalAmount = (FilterData ? (parseInt(FilterData[0]?.amount) + parseInt(FilterData[0]?.extra_amount)) + gst : <></>)

  return (
    <div className="container">

      <div class="col-12">
        <div class="row">
          <div class="col-7">
            <div class="left">
              {/* <img src={'public/front/images/header/rit.png'} alt="" class="img-fluid" /> */}

              <h4 class="mt-3" style={{ fontfamily: 'sansserif', fontsize: '22px' }}>Plan Name: {FilterData ? FilterData[0]?.productinfo : ''} </h4>
              <div className="img-box">
                <img src="https://www.astrocall.live/public/front/images/header/rit.png" alt="" class="img-fluid" style={{ height: '50vh',width:'500px' }}></img>
              </div>
              <p></p>
            </div>
          </div>

          <div class="col-5">
            <div class="right">
              <h3 class="">Payment Details</h3>
              <div class="prize-box prize-box-one mt-3">
                <h6 class="">MRP Total</h6>
                <p class="amount" id="total-amount">₹ {FilterData ? FilterData[0]?.amount : ''}</p>
              </div>
              <div class="prize-box">
                <h6 class="">Bonus</h6>
                <p class="extra_amount" id='extra_amount'>₹{FilterData ? FilterData[0]?.extra_amount : ''} </p>
              </div>

              <div class="prize-box">
                <h6 class="">GST</h6>
                <p class=""> {FilterData ? FilterData[0]?.gst : ''}%</p>
              </div>
              <div class="prize-box">
                <h6 class="">Total Amount</h6>
                <p class="">₹{TotalAmount ? TotalAmount : ''}</p>




              </div>
              {/* <button type='button' className='c-btn' onClick={() => { loadRazorpay() }} >Buy Now</button> */}
              <button type='button' className='c-btn' onClick={() => { UserId ? loadRazorpay() : setLoginModal(true) }} >Buy Now</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Purchase