import React, { useState } from 'react'
import Header from './Components/Front/Include/Header'
import Footer from './Components/Front/Include/Footer'
import LoaderCom from './Components/Front/Utility/LoaderCom'

const Front = ({ cmp }) => {
  const Component = cmp
  const [loading, setLoading] = useState(true)
  setTimeout(() => {
    setLoading(false)
  }, 2000);
  return (
    <>
      <Header />
      {
        // loading ?
        //   <LoaderCom type="spokes" color="#0f4c5c" />
        //   :
        //   <Component />
      }
      <Component />
      <Footer />
    </>
  )
}

export default Front