import React from 'react'

const Service = () => {
  return (

    <>
      <div className="container mt-3 pb-5">
        <div className="about-us">
          <div className="row about-row" id="kundli_matching">
            <div className="col-md-5">
              <div className="img w-100">
                <img className="img-fluid" src="{{asset('front/images/services/kundali.png')}}" alt />
              </div>
            </div>
            <div className="col-md-7">
              <h4 className="h4">Kundli Matching</h4>
              <div className="content-about">
                <p>Janam kundali Matching or Horoscope Matching plays a vital role at the time of marriage. Hindu Scriptures consider marriage as the holy union planned even before one is born. Getting married is also one of the most beautiful moments in one's life. Everyone wants a good spouse with whom she can create some beautiful memories and feel happy. This is the area where a person’s actual happiness lies. In India, where marriage is an essential aspect, people are nowadays interested in finding the perfect life partner. In Hinduism, horoscope or Kundli of both boy and girl are matched by our astrologers, expert in kundali panchang and kundali bhagya to nullify any harmful effects after marriage. Astrology for marriage, how to do kundli matching, and astrology marriage prediction are our core areas of specialization. Also, astrology offers several remedies and solutions that help overcome any difficulty in a marriage.</p>
                <a href=" {{ route('services_details') }} " className="btn btn-md btn-warning float-right pr-3"><strong>ORDER NOW</strong></a>
              </div>
            </div>
          </div>
          <div className="row about-row" >
            <div className="col-md-5">
              <div className="img w-100">
                <img className="img-fluid" src="{{asset('front/images/services/Business & Career.jpeg')}}" alt />
              </div>
            </div>
            <div className="col-md-7">
              <h4>Business &amp; Career</h4>
              <div className="content-about">
                <p>Astrology for career is probably one of the most challenging prediction areas and concerns a person's professional field and direction. People sometimes wonder; does kundli really matter in career? But, great care should be taken when determining a person’s profession. By reading ones birth chart, astrologers make astrology job predictions about career, type of industry, and the lucrative phases for career advancement.<br />
                  The First House or the ascendant is the most important among all astrology houses in the birth chart for predicting one's career prospects. The first House signifies the individual's personality and hence, the planets in this House of the astrology house chart can shape the career path. It is also essential to consider the ruler of the ascendant or the first House in a horoscope.</p>
                <a href="#" className="btn btn-md btn-warning float-right pr-3"><strong>ORDER NOW</strong></a>
              </div>
            </div>
          </div>
          <div className="row about-row">
            <div className="col-md-5">
              <div className="img w-100">
                <img className="img-fluid" src="{{asset('front/images/services/wealth.jpeg')}}" alt />
              </div>
            </div>
            <div className="col-md-7">
              <h4>Wealth &amp; Finance</h4>
              <div className="content-about">
                <p>Money is an essential factor to lead a happy and stress-free life. A few of the people can quickly accumulate wealth and enjoy financial stability despite working less. In contrast, others may find it difficult to save even a little, although they put in all their life into work. The good news is that Astrology can help you understand the reason behind it and suggest ways to improve your wealth and finances.<br />
                  According to astrology, wealth and financial potential can be ascertained by the combination of planets at the time of your birth and in the present. Money and finance astrology can help you in fixing your financial issues.</p>
                <a href="#" className="btn btn-md btn-warning float-right pr-3"><strong>ORDER NOW</strong></a>
              </div>
            </div>
          </div>
          <div className="row about-row">
            <div className="col-md-5">
              <div className="img w-100">
                <img className="img-fluid" src="{{asset('front/images/services/education.jpeg')}}" alt />
              </div>
            </div>
            <div className="col-md-7">
              <h4>Education</h4>
              <div className="content-about">
                <p>The planets Mercury and Jupiter play a vital role in deciding your child's educational and cognitive achievements. When these two planets are well placed in the 2nd House (primary education), 4th House (college graduation) and 9th House (post-graduation), your child would outshine in their education as a valedictorian.<br />
                  We help you by analyzing your child's birth chart and provide solutions based on the planetary positions in your progeny’s Kundali. This delivers an overview for you regarding how you can best support your child.</p>
                <a href="#" className="btn btn-md btn-warning float-right pr-3"><strong>ORDER NOW</strong></a>
              </div>
            </div>
          </div>
          <div className="row about-row">
            <div className="col-md-5">
              <div className="img w-100">
                <img className="img-fluid" src="{{asset('front/images/services/marriage.jpeg')}}" alt />
              </div>
            </div>
            <div className="col-md-7">
              <h4>Love &amp; Marriage</h4>
              <div className="content-about">
                <p>Marriage is an important decision in life; the secret to a happy married life is finding the right person. Finding your perfect match has become a challenging task, especially in today's digitalized world. Vedic Astrology says that many factors influence the compatibility of a married couple. The position of planets in one's birth chart decides the timing of marriage and how life proceeds after marriage. Our services for astrology kundli milan aid with finding the perfect life partner for your loved ones or yourself.<br /> </p>
                <a href="#" className="btn btn-md btn-warning float-right pr-3"><strong>ORDER NOW</strong></a>
              </div>
            </div>
          </div>
          <div className="row about-row" >
            <div className="col-md-5">
              <div className="img w-100">
                <img className="img-fluid" src="{{asset('front/images/services/Relationship.jpg')}}" alt />
              </div>
            </div>
            <div className="col-md-7">
              <h4>Relationship</h4>
              <div className="content-about">
                <p>Marriage is an important decision in life; the secret to a happy married life is finding the right person. Finding your perfect match has become a challenging task, especially in today's digitalized world. Vedic Astrology says that many factors influence the compatibility of a married couple. The position of planets in one's birth chart decides the timing of marriage and how life proceeds after marriage. Our services for astrology kundli milan aid with finding the perfect life partner for your loved ones or yourself.<br />
                  The good news is that many blemishes and dosas can be corrected with simple measures. For astrologers, observing the movements of the planets forms the core towards a responsible and controlled life.</p>
                <a href="#" className="btn btn-md btn-warning float-right pr-3"><strong>ORDER NOW</strong></a>
              </div>
            </div>
          </div>
          <div className="row about-row">
            <div className="col-md-5">
              <div className="img w-100">
                <img className="img-fluid" src="{{asset('front/images/services/family.jpeg')}}" alt />
              </div>
            </div>
            <div className="col-md-7">
              <h4>Family</h4>
              <div className="content-about">
                <p>If you are serious about solving issues related to finances, romance, career or health or any other area of life, contact our astrologers for a consultation. They will analyze your aspects and suggest appropriate astrological remedies. There are specific solutions that will help you solve Problems are inevitable in our daily life, but you can avoid falling prey to them - whether it is related to finance, romance, career or health or any other area of life. </p>
                <a href="#" className="btn btn-md btn-warning float-right pr-3"><strong>ORDER NOW</strong></a>
              </div>
            </div>
          </div>
          <div className="row about-row" >
            <div className="col-md-5">
              <div className="img w-100">
                <img className="img-fluid" src="{{asset('front/images/services/children.jpeg')}}" alt />
              </div>
            </div>
            <div className="col-md-7">
              <h4>Children</h4>
              <div className="content-about">
                <p>Every child is unique and brings blessings to the world, so it is essential to know your child's needs and where their talent lies. Astrology identifies your child's strengths and weaknesses to actualize their fullest potentials using planetary positions and in-depth analysis.<br />
                  Our expert Astrologers will carefully analyze the Ascendant Lord of your child's Moon Sign or astrology rashi to understand your child's unique characteristics. They then provide you with insights into their nature to help nurture the natural gifts your child possesses. </p>
                <a href="#" className="btn btn-md btn-warning float-right pr-3">ORDER NOW</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>


  )
}

export default Service