import moment from "moment";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
export const toastifySuccess = (message) => {
  toast.success(`${message}`, {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
}

export const toastifyError = (message) => {
  toast.error(`${message}`, {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
}

export const DateMonthYear = (dateStr) => {
  return moment(dateStr).format("DD-MM-YY")
}

export const currentDateMonthYear = () => {
  return moment(new Date()).format('DD-MM-YY');
}
export const currentMonthYear = () => {
  return moment(new Date()).format('MM-YY');
}
export const MonthYear = (dateStr) => {
  return moment(dateStr).format("MM-YY")
}
export const getDateTime = (dateStr) => {
  return moment(dateStr).format("DD-MM-yyyy HH:mm")
}

export const currentYearMonthDate = () => {
  return moment(new Date()).format('yyyy-MM-DD');
}

export const calenderWise = () => {
  return moment(new Date()).format('YYYY-MM-DD');
}
