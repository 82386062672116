import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PageBanner from '../../Utility/PageBanner';
import LoaderCom from '../../Utility/LoaderCom';
import { CommonContextData } from '../../../Context/CommonContext';
import plan from '../../../Horoimg/Horos/3.png';
import { onValue, ref } from 'firebase/database';
import { db } from '../../../../firebase';
import ReactStars from "react-rating-stars-component";

const AstrologersList = () => {

    const { loginModal, setLoginModal, astrologin, setastrologin } = useContext(CommonContextData);
    const strdata = JSON.parse(sessionStorage.getItem('UserProfile'));
    const UserIddata = JSON.parse(sessionStorage.getItem('UserProfile'));
    const [UserId, SetUserId] = useState(UserIddata?.data?.data?.id)
    const { loading, setLoading } = useContext(CommonContextData);
    const [data, setData] = useState([]);
    const [Status, SetStatus] = useState(false);
    const [searchVal, setSearchVal] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            const dbRef = ref(db, 'Astrologers');
            onValue(dbRef, (snapshot) => {
                let records = [];
                snapshot.forEach(childSnapshot => {
                    let keyName = childSnapshot.key;
                    let data = childSnapshot.val();
                    records.push({ id: keyName, data: data });
                });
                setData(records);
                setLoginModal(false);
            });
        };
        fetchData();
    }, []);

    const handleSearchChange = (e) => {
        setSearchVal(e.target.value);
    };

    const handleSearchClick = (e) => {
        e.preventDefault();
    };

    const filteredData = data.filter((item) => item.data.verified === true &&
        item.data.name.toLowerCase().includes(searchVal.toLowerCase())
    );

    const handleCallClick = () => {
        window.open("https://play.google.com/store/apps/details?id=app.arustu.astro4call&pcampaignid=web_share", '_blank');
    };

    return (
        <>
            <section className='banner  '>
                <div className="container-fluid">
                    <div className="page-img-banner">
                        <img src={plan} className='img-fluid' alt="banner" />
                    </div>
                </div>
            </section>
            <div className="container" id="bal_recharge">
                <div className="row mt-3">
                    <div className="col-4">
                        <p className='c-btn' style={{ width: '250px' }}>Talk with Astrologers &nbsp; <i className="fa fa-angle-double-right" aria-hidden="true"></i></p>
                    </div>
                    <div className="col-4 mt-2">
                        <p id="MyAccountBalance" className="bal">Available balance: ₹ 0</p>
                    </div>
                    <div className="col-4">
                        <div className="row">
                            <div className="col-4 mt-2">
                                <Link to={'/plans'}>
                                    <button className='btn btn-sm btn-primary'>
                                        Recharge
                                    </button>
                                </Link>
                            </div>
                            <div className="col-8 d-none d-md-block">
                                <form className="d-flex" onSubmit={handleSearchClick}>
                                    <div className="input-group ">
                                        <input
                                            type="search"
                                            id="search_astro_input"
                                            name='search_astro_input'
                                            className="form-control"
                                            placeholder="Search"
                                            value={searchVal}
                                            onChange={handleSearchChange}
                                        />
                                        <div className="input-group-append">
                                            <button
                                                className="btn astro_search_btn"
                                                style={{ border: '1px solid #ff5722', color: '#ff5722' }}
                                                type="submit"
                                            >
                                                <i className="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="astrologers-list">
                <div className="container">
                    <div className="row g-3">
                        {loading ? (
                            <LoaderCom type="spokes" color="#bd0001" />
                        ) : (
                            filteredData.map((item) => (
                                <div className="col-12 col-sm-12 col-md-6 col-lg-4" key={item.id}>
                                    <div className="bs">
                                        <div className="astro-box">
                                            <div className="img-box">
                                                <img
                                                    src={item?.data?.profile}
                                                    alt="profile"
                                                />
                                                <ReactStars
                                                    value={item?.data?.star_count}
                                                    isHalf={true}
                                                    count={5}
                                                    size={20}
                                                    activeColor="#ffd700"
                                                />
                                            </div>
                                            <div className="text ms-3">
                                                <h6 >
                                                    {item?.data?.name}
                                                </h6>
                                                <span style={{ textTransform: 'uppercase' }}>{item?.data?.graduation?.length > 25 ? `${item?.data.graduation.substring(0, 25)}...` : item?.data?.graduation}</span>
                                                <span>{item?.data.languages?.length > 25 ? `${item?.data.languages.substring(0, 25)}...` : item?.data.languages}</span>
                                                <span ><strong>Exp:  </strong>{item?.data?.exp_year} Years</span>
                                                <span><strong>₹ {item?.data?.rate}</strong>/min</span>
                                                <div style={{ color: '#e76363', fontSize: '13px' }}>
                                                    <p>
                                                        {(item?.data?.isOnlineAudio || item?.data?.isOnlineChat) === false ?
                                                            <span>Again Login : {(item?.data?.nxtOnlineDate) + ' ' + (item?.data?.nxtOnlineTime)} </span>
                                                            : ''}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="verify-icon">
                                                <img src="/images/verified.png" style={{ height: '25px' }} alt="verified" />
                                            </div>
                                            <div className="astro-btn-box">
                                                {
                                                    <div>
                                                        {
                                                            item?.data?.isOnlineAudio ?
                                                                <button onClick={() => {
                                                                    { UserId ? SetStatus(true) : setLoginModal(true) }
                                                                    // SetStatus(true)
                                                                }}>
                                                                    <i className='fa fa-phone'></i> call </button>
                                                                :
                                                                ''
                                                        }
                                                        {
                                                            item?.data?.isOnlineChat ?
                                                                <button onClick={() => {
                                                                    { UserId ? SetStatus(true) : setLoginModal(true) }
                                                                    // SetStatus(true)
                                                                }}><i className='fa fa-commenting'></i> chat</button>
                                                                :
                                                                ''
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            <div className={`spinner-grow spinner-grow-sm me-1 
                                                        ${item?.data?.status === 'busy' ? "asdas" : (item?.data?.isOnlineAudio || item?.data?.isOnlineChat) === true ? "text-success" : "text-danger"}`}
                                                role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                            {item?.data?.status}
                                        </div>
                                        <div className="mt-3 text-end col-12" >
                                            <Link to={`/AstrologersProfile?AP=${item?.data?.id}`}
                                                style={{ color: '#ff751a' }}>
                                                View Profile
                                            </Link>
                                        </div>
                                        {/* <button type='button' onClick={()=>{setLoginModal(false)}}>click</button> */}
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
                {
                    Status &&
                    <div id="c-modal">
                        <div className="c-modal-body py-3">
                            <div className="c-modal-header d-flex justify-content-between px-2 bb">
                                <h5 className='text-danger'>Download</h5>
                                <h4 onClick={() => SetStatus(false)} style={{ cursor: "pointer" }}><i className='fa fa-times'></i></h4>
                            </div>
                            <h4 className='text-center mt-2 text-bold'>Are You Want To Download ?</h4>
                            <div className='col-12 text-end mt-4'>
                                <button type='button' className='c-btn mx-2' onClick={() => { SetStatus(false); handleCallClick() }} >download</button>
                                <button className='c-btn' onClick={() => { SetStatus(false) }} >Cancel</button>
                            </div>
                        </div>
                    </div>

                }
            </div>

        </>
    );
};

export default AstrologersList;
