// import 'devextreme/dist/css/dx.light.css';
import { Route, Routes } from 'react-router-dom';
import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Front from './Front';
import Home from './Components/Front/Pages/Home/Home';
import AstrologersList from './Components/Front/Pages/Astrologers/AstrologersList';
import About from './Components/Front/Pages/About/About';
import Plans from './Components/Front/Pages/Plans/Plans';
import Astromall from './Components/Front/Pages/Astromall/Astromall';
import CategoryPage from './Components/Front/Pages/Astromall/CategoryPage';
import Horoscope from './Components/Front/Pages/Horoscope/Horoscope';
import Login from './Components/Front/Login';
import Kundali from './Components/Front/Pages/Kundali/Kundali';
import Contact from './Components/Front/Pages/Contact/Contact';
import Service from './Components/Front/Pages/Service/Service';
import Purchase from './Components/Front/Pages/Plan_purchase/Purchase';
import ProductDetails from './Components/Front/Pages/Astromall/ProductDetails/ProductDetails';
import ProductBuynow from './Components/Front/Pages/Astromall/ProductDetails/ProductBuynow';
import kundlidetails from './Components/Front/Pages/kundliDetails/kundlidetails';
import Profile from './Components/Front/Pages/Profile/Profile';
import History from './Components/Front/Pages/History/History';
import AstrologersProfile from './Components/Front/Pages/Astrologers/AstrologersProfile';
import { useEffect } from 'react';
import Gemstone from './Components/Front/Pages/Astromall/Category/Gemstone';
import OnlinePuja from './Components/Front/Pages/Astromall/Category/OnlinePuja';
// import 'primereact/resources/primereact.css';
// import 'primereact/resources/themes/lara-light-indigo/theme.css';

function App() {
  return (
    <Routes>
      {/* <Route path='/' element={<Login />} /> */}
      <Route path='/' element={<Front cmp={Home} />} />
      <Route path='/astrologers' element={<Front cmp={AstrologersList} />} />
      <Route path='/about' element={<Front cmp={About} />} />
      <Route path='/plans' element={<Front cmp={Plans} />} />
      <Route path='/astromall' element={<Front cmp={Astromall} />} />
      <Route path='/Gemstone' element={<Front cmp={Gemstone} />} />
      <Route path='/OnlinePuja' element={<Front cmp={OnlinePuja} />} />
      {/* <Route path='/astromall/:page' element={<Front cmp={CategoryPage} />} /> */}
      {/* <Route path='/horoscope/:horoscope' element={<Front cmp={Horoscope} />} /> */}
      <Route path='/horoscope' element={<Front cmp={Horoscope} />} />
      <Route path='/Kundali' element={<Front cmp={Kundali} />} />
      <Route path='/contact' element={<Front cmp={Contact} />} />
      <Route path='/service' element={<Front cmp={Service} />} />
      <Route path='/purchase' element={<Front cmp={Purchase} />} />
      <Route path='/Profile' element={<Front cmp={Profile} />} />
      <Route path='/ProductDetails' element={<Front cmp={ProductDetails} />} />
      <Route path='/ProductBuynow' element={<Front cmp={ProductBuynow} />} />
      <Route path='/kundlidetails' element={<Front cmp={kundlidetails} />} />
      <Route path='/History' element={<Front cmp={History} />} />
      <Route path='/AstrologersProfile' element={<Front cmp={AstrologersProfile} />} />
    </Routes>
  );
}

export default App;
