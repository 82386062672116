import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import plan from '../../../Horoimg/Horos/1.png'

const Kundali = () => {

	const gettoken = sessionStorage.getItem('token');
	const navigate = useNavigate();
	const [token, settoken] = useState()
	const [kundalistatus, setkundalistatus] = useState(false)
	const [yogadata, setyogadata] = useState(null)
	const [nakshatradata, setnakshatradata] = useState(null)
	const [chandrarasidata, setchandrarasidata] = useState(null)
	const [sooryarasi, setsooryarasi] = useState(null)
	const [zodiacdata, setzodiacdata] = useState(null)
	const [additionalInfo, setAdditionalInfo] = useState(null);
	const [latitudedata, setlatitudedata] = useState()
	const [longitude, setlongitude] = useState()
	const [selectedOption, setSelectedOption] = useState('basic');
	const [value, setValue] = useState({
		name: '',
		birth_city: '',
		datetime: '',
		coordinates: '',
		ayanamsa: '',
		result_type: 'basic',
		la: '',
		birth_city: '',
		birth_state: ''
	})
	const [errors, setErrors] = useState({
		datetime: '',
		ayanamsa: '',
		birth_city: '',
		birth_state: ''
	})

	const Handlechange = (e) => {
		setValue({
			...value,
			[e.target.name]: e.target.value
		})
	}

	// const handleChangedatetime = (e) => {
	// 	const { name, value } = e?.target;
	// 	const formattedDate = formatDate(value);
	// 	setValue({
	// 		...value,
	// 		[name]: value
	// 	});
	// };

	const formatDate = (datetime) => {
		const offset = '+05:30'
		const [datePart, timePart] = datetime?.split('T');
		const [year, month, day] = datePart.split('-').map(Number);
		const [hour, minute] = timePart?.split(':')?.map(Number);
		const date = new Date(year, month - 1, day, hour, minute);
		return date?.toISOString()?.slice(0, 19) + ("Z", offset);
	};

	const handleChangeayanamsa = (event) => {
		setValue({
			...value,
			ayanamsa: event?.target?.value
		});
	};

	const getAccessToken = async () => {
		const url = 'https://api.prokerala.com/token';
		const data = {
			audience: "https://api.prokerala.com/",
			grant_type: 'client_credentials',
			client_id: '57725118-dc08-4038-94ef-d4a0082e8b4f',
			client_secret: 'NYQaS4a602h3KDq09deVov6Bkq0RePwLgIjFJUwO',
		}
		try {
			const response = await axios.post(url, data, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				},
			})
			settoken(response.data.access_token)
			sessionStorage.setItem('token', response.data.access_token);
		} catch (error) {
			console.log('error:', error)
		}
	}

	const get_coordinates = async () => {
		try {
			const params = {
				access_key: '8785ba45b8a20a205fa9bd9e9d5e5520',
				query: `${value?.birth_city},${value?.birth_state}`,
			};
			const response = await axios.get('https://api.positionstack.com/v1/forward', { params });
			console.log(response, 'response')
			const locationData = response?.data?.data[0];
			if (locationData) {
				setlatitudedata(locationData?.latitude);
				setlongitude(locationData?.longitude);
				setValue({
					...value,
					coordinates: `${locationData.latitude},${locationData.longitude}`,
				});
			}
		} catch (error) {
			console.log(error);
		}
	};

	const get_panchang = async (e) => {
		// const url = '/v2/astrology/kundli';
		//  "proxy":"https://api.prokerala.com",
		const url = 'https://api.prokerala.com/v2/astrology/kundli';
		const { name, result_type, birth_city, datetime, la, ayanamsa, coordinates } = value;
		const params = {
			'ayanamsa': ayanamsa,
			'coordinates': coordinates,
			// 'datetime': '2004-02-12T15:19:21+05:30',
			// 'birth_city': birth_city,
			// 'coordinates': `${23.1765},${75.7885}`,
			'datetime': formatDate(datetime),
			'result_type': result_type,
			'la': la,
			// 'name': name
		};
		console.log(params, 'params')
		const config = {
			headers: {
				"Authorization": `Bearer ${gettoken}`
			},
			params: params
		};
		try {
			const response = await axios.get(url, config);
			if (response.status === 200) {
				setAdditionalInfo(response.data.data.nakshatra_details.additional_info);
				setnakshatradata(response.data.data.nakshatra_details.nakshatra.name);
				setchandrarasidata(response.data.data.nakshatra_details.chandra_rasi.name);
				setsooryarasi(response.data.data.nakshatra_details.soorya_rasi.name);
				setzodiacdata(response.data.data.nakshatra_details.zodiac.name);
				setyogadata(response.data.data.yoga_details);
				setkundalistatus(true)
			}
			console.log(response, 'response')
		} catch (error) {
			if (error.response.status === 401) {
				getAccessToken()
			}
		}
	}

	const handleOptionChange = (e) => {
		setValue({ ...value, result_type: e.target.value });
	};
	const infoArrayadditional = additionalInfo ? Object.entries(additionalInfo) : [];
	const checkValidationErrors = () => {
		const newErrors = {};
		if (!value?.datetime) {
			newErrors.datetime = 'required *';
		}
		if (!value?.ayanamsa) {
			newErrors.ayanamsa = 'required *';
		}
		if (!value?.birth_city) {
			newErrors.birth_city = 'required *';
		}
		if (!value?.birth_state) {
			newErrors.birth_state = 'required *';
		}
		if (!value?.la) {
			newErrors.la = 'required *';
		}
		setErrors(newErrors);
		if (Object.keys(newErrors).length === 0) {
			get_panchang();
		}
	};

	return (
		<>
			<section className='banner '>
				<div className="container-fluid ">
					<div className="page-img-banner  ">
						<img src={plan} className='img-fluid' />
					</div>
				</div>
			</section>
			<div className="logo mt-1">
				{kundalistatus ? (
					<>
						<div>
							<div className="kundli_details">
								<div className="container">
									<nav>
										<div className="nav nav-tabs" id="nav-tab" role="tablist">
											<button className="nav-link active" id="nav-panchang-tab" data-bs-toggle="tab" data-bs-target="#nav-panchang" type="button" role="tab" aria-controls="nav-panchang" aria-selected="true">
												nakshatra details
											</button>
											<button className="nav-link" id="nav-yoga-tab" data-bs-toggle="tab" data-bs-target="#nav-yoga" type="button" role="tab" aria-controls="nav-yoga" aria-selected="false">
												Yoga Details
											</button>
										</div>
									</nav>
									<div className="tab-content" id="nav-tabContent">
										<div className="tab-pane fade show active" id="nav-panchang" role="tabpanel" aria-labelledby="nav-panchang-tab">
											<table class="table table-bordered mb-5 table-hover">
												<tbody><tr class="bg-secondary text-white text-center"><th colspan="2&quot;">Nakshatra Details</th></tr>
													<ul>
														<li>
															<strong>Nakshatra:</strong>{nakshatradata}
														</li>
														<li>
															<strong>Chandra Rasi:</strong>{chandrarasidata}
														</li>
														<li>
															<strong>Soorya Rasi:</strong>{sooryarasi}
														</li>
														<li>
															<strong>Zodiac:</strong>{zodiacdata}
														</li>
													</ul>
													<tr class="bg-secondary text-white text-center"><th colspan="2&quot;">Additional Info</th></tr>
													<ul>
														{infoArrayadditional.map(([key, value]) => (
															<li key={key}>
																<strong>{key.replace(/_/g, ' ')}:</strong> {value}
															</li>
														))}
													</ul>
												</tbody></table>
										</div>
										<div className="tab-pane fade" id="nav-divisional" role="tabpanel" aria-labelledby="nav-divisional-tab">
										</div>
										<div className="tab-pane fade" id="nav-yoga" role="tabpanel" aria-labelledby="nav-yoga-tab">
											<div class="freereport">
												<h3>Yoga Details</h3>
												{yogadata.map((yoga, index) => (
													<div key={index}>
														<p><strong>{yoga.name}:</strong> {yoga.description}</p>
													</div>
												))}

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</>
				) : (
					<div className="container">
						<h6 className="header-panel">Make Free Kundli</h6>
						<div class="container-fluid section-profile" id="form-innner">
							<div class="row">
								<div class="col-xl-6">
									<div class="form-group">
										<label for="">Name</label>
										<input
											type="text" name="name" id='name' value={value?.name} placeholder="Name" onChange={Handlechange}
											class="form-control" required=""
										/>
										<small></small>
									</div>
								</div>
								<div class="col-xl-6">
									<div class="form-group">
										<label>Ayanamsa
											{errors.ayanamsa && <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }} className="error-message">{errors.ayanamsa}</p>}
										</label>
										<select name="ayanamsa" className="form-select" value={value?.ayanamsa} onChange={handleChangeayanamsa}>
											<option value="">Select...</option>
											<option value="1">Lahiri</option>
											<option value="3">Raman</option>
											<option value="5">KP</option>
										</select>
									</div>
								</div>
							</div>
							<div class="row mt-2">
								<div class="col-xl-6">
									<div class="form-group">
										<label for="">Date & Time
											{errors?.datetime && <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }} className="error-message">{errors?.datetime}</p>}
										</label>
										<input
											type="datetime-local"
											className="form-control"
											name="datetime"
											value={value?.datetime}
											onChange={Handlechange}
											required
										/>
									</div>
								</div>
								<div class="col-xl-6">
									<div class="form-group">
										<label>Language
											{errors?.la && <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }} className="error-message">{errors?.la}</p>}

										</label>
										<select name="la" class="form-control" value={value?.la} onChange={Handlechange}>
											<option value="">Select...</option>
											<option value="en">English</option>
											<option value="hi">Hindi</option>
											<option value="ta">Tamil</option>
											<option value="ml">Malayalam</option>
										</select>
									</div>
								</div>
							</div>
							<div class="row mt-2">
								<div class="col-md-6">
									<label for="">Place of City
										{errors.birth_city && <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }} className="error-message">{errors.birth_city}</p>}
									</label>
									<input
										onBlur={get_coordinates}
										type="text"
										placeholder="Birth City"
										name="birth_city"
										class="form-control"
										value={value?.birth_city}
										onChange={Handlechange}
										required=""
									/>
								</div>
								<div class="col-md-6">
									<label for="">Place of State
										{errors.birth_state && <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }} className="error-message">{errors.birth_state}</p>}
									</label>
									<input
										onBlur={get_coordinates}
										type="text"
										placeholder="Birth Place"
										name="birth_state"
										class="form-control"
										value={value?.birth_state}
										onChange={Handlechange}
										required=""
									/>
								</div>
							</div>
							<div class="row mt-2">
								<div class="col-md-6">
									<div class="form-group row">
										<label class="col-sm-3 col-md-4 col-form-label  text-md-right text-xs-left">Result Type: </label>
										<div >
											<div class="form-check form-check-inline">
												<input
													type="radio"
													name="result_type"
													value="basic"
													checked={value?.result_type === 'basic'}
													onChange={handleOptionChange}
												/>

												<label class="form-check-label" for="result_type1">Basic</label>
											</div>
											<div class="form-check form-check-inline">
												<input
													type="radio"
													name="result_type"
													value="advanced"
													checked={value?.result_type === 'advanced'}
													onChange={handleOptionChange}
												/>
												<label class="form-check-label" for="result_type2">Advanced</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<br />
							<button type="submit" className='c-btn' onClick={() => { checkValidationErrors() }}  >Generate Kundli</button>
						</div>
					</div>
				)}
			</div>
		</>

	);
};

export default Kundali;
