import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import banner from '../../../Horoimg/our app/3.png'
import banner1 from '../../../Horoimg/our app/2.png'
import banner2 from '../../../Horoimg/our app/4.png'
import banner3 from '../../../Horoimg/our app/5.png'
import kundli from '../../astroimg/worship.png'
import relation from '../../astroimg/married.png'
import family from '../../astroimg/family.png'
import boy from '../../astroimg/sibling.png'
import education from '../../astroimg/book.png'
import finance from '../../astroimg/money-bag.png'
import marrige from '../../astroimg/rings.png'
import business from '../../astroimg/cooperation.png'
import panchang from '../../../Horoimg/icons/pp (1).png'
import kundali from '../../../Horoimg/icons/Kundali Matching (1).png'
import gemstone from '../../../Horoimg/icons/Gemstone (1).png'
import dailyhoro from '../../../Horoimg/icons/Free Daily Horoscope.png'
import online from '../../../Horoimg/icons/Online Puja.png'
import service from '../../../Horoimg/icons/Free Services copy.png'
import blog from '../../../Horoimg/icons/Astrology Blog copy.png'
const Home = () => {
    const [kundaliVal, setKundaliVal] = useState({
        'name': '',
        'gender': '',
        'dob': '',
        'time': '',
        'birthPlace': '',
        'birthCity': '',
    })
    const kundaliChange = (e) => {
        setKundaliVal({
            ...kundaliVal,
            [e.target.name]: e.target.value
        })
    }
    const next = () => {
        console.log(kundaliVal)
        console.log("ok")
    }
    const jsonString = '["main-banner.jpg"]'; // Your JSON string

    const [bannerImg, setBannerImg] = useState([])
    const [imgBaseURL, setImgBaseURL] = useState('')
    useEffect(() => {
        getBannerImg()
    }, [])
    const getBannerImg = async () => {
        try {
            const res = await axios.get('http://astrocall.live/api/v1/get-banner');
            // console.log(res?.data)
            if (res?.data?.status) {
                setImgBaseURL(res?.data?.data?.imgBaseURL)
                setBannerImg(res?.data?.data?.banner)
            }
        } catch (error) {
            console.log(error)
        }
    }


    const getImgPath = (jsonString) => {
        try {
            const jsonArray = JSON.parse(jsonString);
            if (Array.isArray(jsonArray) && jsonArray.length > 0) {
                const imageFilename = jsonArray[0];
                return imageFilename
            } else {
                console.log('JSON array is empty or not an array.');
            }
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    }

    return (
        <>
            <section className='category-section ' >
                <div className="container d-flex justify-content-center ">
                    <div className="new-icon">
                        <div className="content text-center">
                            <Link to={'#'}>
                                <img src={panchang} className='img-fluid new-img' />
                                <h5>panchang</h5>
                            </Link>
                        </div>
                    </div>
                    <div className="new-icon">
                        <div className="content text-center">
                            <Link to={'/Kundali'}>
                                <img src={kundali} className='img-fluid new-img' />
                                <h5>Make Kundli Matching</h5>
                            </Link>
                        </div>
                    </div>
                    <div className="new-icon">
                        <div className="content text-center">
                            <Link to={'/Gemstone'}>
                                <img src={gemstone} className='img-fluid new-img' />
                                <h5>gemstone</h5>
                            </Link>
                        </div>
                    </div>
                    <div className="new-icon">
                        <div className="content text-center">
                            <Link to={'/horoscope'}>
                                <img src={dailyhoro} className='img-fluid new-img' />
                                <h5>Horoscope</h5>
                                
                            </Link>
                        </div>
                    </div>
                    <div className="new-icon">
                        <div className="content text-center">
                            <Link to={'/OnlinePuja'}>
                                <img src={online} className='img-fluid new-img' />
                                <h5>Online pooja</h5>
                            </Link>
                        </div>
                    </div>
                    <div className="new-icon">
                        <div className="content text-center">
                            <Link to={'/service'}>
                                <img src={service} className='img-fluid new-img' />
                                <h5>Free Services</h5>
                            </Link>
                        </div>
                    </div>
                    <div className="new-icon">
                        <div className="content text-center">
                            <Link to={'#'}>
                                <img src={blog} className='img-fluid new-img' />
                                <h5>Blog</h5>
                            </Link>
                        </div>
                    </div>
                    {/* <div className="col-6 col-sm-6 col-md-3">
                            <div className="">
                                <Link to={'/astrologers'}>
                                    <div className="content text-center">
                                        <img src={talk} className='img-fluid new-img' />
                                        <h5>talk with astrologers</h5>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-3">
                            <div className="">
                                <Link to={'#'}>
                                    <div className="content text-center">
                                        <img src={chat} className='img-fluid new-img' />
                                        <h5>chat with astrologers</h5>
                                    </div>
                                </Link>
                            </div>
                        </div> */}
                    {/* <div className="col-6 col-sm-6 col-md-3">
                            <div className="bs">
                                <Link to={'#'}>
                                    <div className="content text-center">
                                        <img src={chat} className='img-fluid' />
                                        <h5>chat with astrologers</h5>
                                    </div>
                                </Link>
                            </div>
                        </div> */}
                    {/* <div className="col-6 col-sm-6 col-md-3">
                            <div className="">
                                <Link to={'#'}>
                                    <div className="content text-center">
                                        <img src={puja} className='img-fluid new-img' />
                                        <h5>online pooja</h5>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-3">
                            <div className="">

                                <Link to={'#'}>
                                    <div className="content text-center">
                                        <img src={shopping} className='img-fluid new-img' />
                                        <h5>shopping cart</h5>

                                    </div>
                                </Link>
                            </div>
                        </div> */}
                </div>
                {/* <div className='back-sec'>
                    <div class="container" >
      <div class="container-fluid topAbout" id='form-innner'>
        <div class="row">
          <div class="col-xl-4 text-center font-weight-bold borderRight">
            <h1 style={{fontsize:  '3.5rem'}}><i class="fa fa-user"></i><span class="pl-2">5000</span><span style={{verticalAlign: 'super', fontsize: '30px'}}>+</span></h1>
            <h4 style={{color: '#ff5722'}}>Happy Clients </h4>
          </div>

          <div class="col-xl-4 text-center font-weight-bold borderRight">
            <h1 style={{fontsize: '3.5rem'}}><i class="fa fa-user-circle-o"></i><span class="pl-2">20</span>  <span style={{verticalalign: 'super' , fontsize: '30px'}}>+</span> </h1>
            <h4 style={{color: '#ff5722'}}>Verified Astrologers </h4>
          </div>

          <div class="col-xl-4 text-center font-weight-bold">
            <h1 style={{fontsize: '3.5rem'}}><i class="fa fa-building"></i><span class="pl-2">10</span> <span style={{verticalalign: 'super' , fontsize: '30px'}}>+</span> </h1>
            <h4 style={{color: '#ff5722'}}>Countries </h4>
          </div>
        </div>     
      </div>
    </div>
                    </div> */}
            </section>
            <section className='banner-section'>
                <div className="container-fluid ">
                    <div className="row">
                        {/* <div className="col-12 col-md-8">
                            <div className="banner-box">
                                <img src="/images/page/home.png" className='img-fluid' />
                            </div>
                            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-inner">
                                    {
                                        bannerImg.map((data, i) => (
                                            <div class={`carousel-item ${i === 1? "active" : ""}`}>
                                                <img src={imgBaseURL + getImgPath(data.images)} class="d-block w-100" alt="..." />
                                            </div>
                                        ))
                                    }
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div> */}
                        <div className="container ">

                            <div className="col-12 col-md-12 col-lg-12">
                                <div id="newsCarousel1" className="carousel slide" data-bs-ride="carousel">
                                    <div className="carousel-inner">
                                        <div className="carousel-item active " style={{ position: 'relative' }}>
                                            <img src={banner} className=" w-100 img-fluid " alt="..." style={{ borderRadius: '10px', }} />
                                            <a href="https://play.google.com/store/search?q=astrocall&c=apps" target='_blank' className='b-btn'>
                                                Download Now
                                            </a>
                                        </div>
                                        <div className="carousel-item ">
                                            <img src={banner1} className=" w-100 img-fluid" alt="..." style={{ borderRadius: '10px' }} />
                                            <Link to={'/astrologers'}>
                                                <button className='b-btn' >Chat Now</button>
                                            </Link>
                                        </div>
                                        <div className="carousel-item ">
                                            <img src={banner2} className=" w-100 img-fluid" alt="..." style={{ borderRadius: '10px' }} />
                                            <Link to={'/astrologers'}>
                                                <button className='b-btn' >Chat Now</button>
                                            </Link>
                                        </div>
                                        <div className="carousel-item ">
                                            <img src={banner3} className=" w-100 img-fluid" alt="..." style={{ borderRadius: '10px' }} />
                                            <Link to={'/astromall'}>
                                                <button className='b-btn' >Book Now</button>
                                            </Link>
                                        </div>
                                    </div>
                                    <button className="carousel-control-prev" type="button" data-bs-target="#newsCarousel1" data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#newsCarousel1" data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-12 col-md-4">
                            <div id="newsCarousel" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <img src="/images/page/home-news.png" class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="/images/page/home-news.png" class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="/images/page/home-news.png" class="d-block w-100" alt="..." />
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#newsCarousel" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#newsCarousel" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            <section className='kundali-punchang-section mt-5'>
                <div className="container">
                    <h2 className='text-center'>KUNDALI  MATCHING</h2>
                    <div className="row g-3">
                        <div className="col-12  col-md-6 col-lg-4 mb-3">
                            <div className="ks">
                                <h6 className='title'>Make Kundli Matching</h6>
                                <div className="input-box">
                                    <input type="text" placeholder='Name' />
                                </div>
                                <div className="input-box">
                                    <select>
                                        <option value="">--select gender--</option>
                                        <option value="male">male</option>
                                        <option value="female">female</option>
                                        <option value="other">other</option>
                                    </select>
                                </div>
                                <div className="input-box">
                                    <input type="date" placeholder='Name' />
                                </div>
                                <div className="input-box">
                                    <input type="time" placeholder='Name' />
                                </div>
                                <div className="input-box">
                                    <input type="text" placeholder='birth place' />
                                </div>
                                <div className="input-box">
                                    <input type="text" placeholder='birth city' />
                                </div>
                                <div className="input-box">
                                    <button className='w-100 c-btn'>generate kundli</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-3">
                            <div className="ks">
                                <h6 className='title'>Kundali matching - fill girl's details</h6>
                                <div className="input-box">
                                    <input type="text" placeholder='Name' name='name' onChange={kundaliChange} />
                                </div>
                                <div className="input-box">
                                    <select name='gender' onChange={kundaliChange}>
                                        <option value="">--select gender--</option>
                                        <option value="male">male</option>
                                        <option value="female">female</option>
                                        <option value="other">other</option>
                                    </select>
                                </div>
                                <div className="input-box">
                                    <input type="date" placeholder='Name' name='dob' onChange={kundaliChange} />
                                </div>
                                <div className="input-box">
                                    <input type="time" placeholder='Name' name='time' onChange={kundaliChange} />
                                </div>
                                <div className="input-box">
                                    <input type="text" placeholder='birth place' name='birthPlace' onChange={kundaliChange} />
                                </div>
                                <div className="input-box">
                                    <input type="text" placeholder='birth city' name='birthCity' onChange={kundaliChange} />
                                </div>
                                <div className="input-box">
                                    <button className='w-100 c-btn' onClick={() => next()}>continue to enter boy's details</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-3">
                            <div className="ks">
                                <h6 className='title'>Punchang</h6>
                                <h6>New Delhi, India (8 September, 2023)</h6>
                                <hr />
                                <div className='text-center'>
                                    <h6><strong>Tithi:</strong> Krishna Navami upto 17:32:39</h6>
                                    <h6><strong>Month Amanta:</strong>  Shravan</h6>
                                    <h6><strong>Tithi:</strong> Krishna Navami upto 17:32:39</h6>
                                    <h6><strong>Day:</strong> Shukravara | <strong>Samvat:</strong> 2080</h6>
                                </div>
                                <hr />
                                <div className='text-center'>
                                    <h6><strong>Month Amanta:</strong>  Shravan</h6>
                                    <h6><strong>Tithi:</strong> Krishna Navami upto 17:32:39</h6>
                                    <h6><strong>Day:</strong> Shukravara | <strong>Samvat:</strong> 2080</h6>
                                </div>
                                <div className="input-box mt-3 pt-1">
                                    <button className='w-100 c-btn'>continue to enter boy's details</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={1} aria-label="Slide 2" />
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={2} aria-label="Slide 3" />
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item">
                        <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item">
                        <img src="https://via.placeholder.com/800x400" className="d-block w-100" alt="..." />
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true" />
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true" />
                    <span className="visually-hidden">Next</span>
                </button>
            </div> */}

            <section className='banner-card'>
                <div className="container-fluid mt-3">
                    <h2 className='text-center'>ASTROLOGY SERVICES</h2>
                    <div id="carouselExampleControls" className="carousel carousel-dark slide" data-bs-ride="carousel">
                        <div className="carousel-inner mt-3">
                            <div className="carousel-item active">
                                <div className="card-wrapper container d-flex  justify-content-around">
                                    <div className="row ">
                                        <div className="e-card playing text-center col-12 col-md-4 col-lg-4 mb-3">
                                            <div className="image " >
                                                <img src={kundli} alt="" className='text-center' />
                                            </div>
                                            <div className="wave" />
                                            <div className="wave" />
                                            <div className="wave" />
                                            <div className="infotop">
                                                <h5>
                                                    KUNDLI MATCHING
                                                </h5>
                                                <div className="name">This is one of the most important factors as matching of groom and bride Kundli would enable to live a happy life after their marriage. Marriage is a precious part as the whole life is dependent on it, so it is necessary to have a perfect match, especially in Hinduism.</div>
                                                <div className=''>
                                                    <Link to={'#'} className="btn btn-primary">
                                                        Read More
                                                    </Link>
                                                </div>
                                            </div>

                                            <br />
                                        </div>
                                        <div className="e-card playing text-center col-12 col-md-4 col-lg-4 mb-3">
                                            <div className="image " >
                                                <img src={relation} alt="" className='text-center' />

                                            </div>
                                            <div className="wave" />
                                            <div className="wave" />
                                            <div className="wave" />
                                            <div className="infotop">
                                                <h5>

                                                    RELATIONSHIP
                                                </h5>

                                                <div className="name">Astrologers have great research regarding the planets as men from the planet Mars and females from Venus are considered a happy relationship. The birth chart reveals such data of planets which ensures a great life ahead. The astrocall app has the best</div>
                                                <div className=''>
                                                    <a href="#" className="btn btn-primary">Read More</a>

                                                </div>
                                            </div>

                                            <br />
                                        </div>
                                        <div className="e-card playing text-center col-12 col-md-4 col-lg-4 mb-3">
                                            <div className="image " >
                                                <img src={family} alt="" className='text-center' />
                                            </div>
                                            <div className="wave" />
                                            <div className="wave" />
                                            <div className="wave" />
                                            <div className="infotop">
                                                <h5>

                                                    FAMILY
                                                </h5>
                                                <div className="name">Problems have become a part of life whether it is related to career, education, family, wealth, romance, and many more. It cannot be avoidable but measures could be adopted to find out the best remedy to them. astrocall app provides 24*7 hours support.</div>
                                                <div className=''>
                                                    <a href="#" className="btn btn-primary">Read More</a>

                                                </div>
                                            </div>

                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item ">
                                <div className="card-wrapper container d-flex  justify-content-around">
                                    <div className="row gx-5">
                                        <div className="e-card playing text-center col-12 col-md-6 col-lg-6 mb-3">
                                            <div className="image " >
                                                <img src={boy} alt="" className='text-center' />

                                            </div>
                                            <div className="wave" />
                                            <div className="wave" />
                                            <div className="wave" />
                                            <div className="infotop">
                                                <h5>

                                                    Children
                                                </h5>
                                                <div className="name">Astrocall renders expert astrologers who can depict the child’s potential and their weaknesses to enhance a bright future by suggesting improvements. They carefully observe the planets and signs and thus provide an optimal solution.
                                                </div>
                                                <div className=''>
                                                    <a href="#" className="btn btn-primary">Read More</a>

                                                </div>
                                            </div>

                                            <br />
                                        </div>
                                        <div className="e-card playing text-center col-12 col-md-6 col-lg-4 mb-3">
                                            <div className="image " >
                                                <img src={education} alt="" className='text-center' />
                                            </div>
                                            <div className="wave" />
                                            <div className="wave" />
                                            <div className="wave" />
                                            <div className="infotop">
                                                <h5>
                                                    EDUCATION

                                                </h5>

                                                <div className="name">Two planets named Jupiter and Mercury play a significant role in deciding the achievement of children in the education department. The right position of this planet in children’s Kundli would depict that they could outshine in their career.</div>
                                                <div className=''>
                                                    <a href="#" className="btn btn-primary">Read More</a>
                                                </div>
                                            </div>

                                            <br />
                                        </div>
                                        <div className="e-card playing text-center col-12 col-md-6 col-lg-4 mb-3">
                                            <div className="image " >
                                                <div className="image " >
                                                    <img src={business} alt="" className='text-center' />
                                                </div>
                                            </div>
                                            <div className="wave" />
                                            <div className="wave" />
                                            <div className="wave" />
                                            <div className="infotop">
                                                <h5>

                                                    BUSINESS & CAREER
                                                </h5>
                                                <div className="name">The career phase is the most challenging part in students’ life as it provides a clear-cut direction towards a professional journey. Student’s in today’s world feel stressed and anxious at a very young age which leads to some diseases like hypertension.

                                                </div>
                                                <div className=''>
                                                    <a href="#" className="btn btn-primary">Read More</a>
                                                </div>
                                            </div>

                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item ">
                                <div className="card-wrapper container d-flex  justify-content-around">
                                    <div className="row gx-5">
                                        <div className="e-card playing text-center col-12 col-md-6 col-lg-6 mb-3">
                                            <div className="image " >
                                                <img src={finance} alt="" className='text-center' />
                                            </div>
                                            <div className="wave" />
                                            <div className="wave" />
                                            <div className="wave" />
                                            <div className="infotop">
                                                <h5>
                                                    WEALTH & FINANCE
                                                </h5>
                                                <div className="name">Some people are worried about their financial viability and do not have a secure future. They spend little and live a stressed and depressed life. In comparison to this, while other people enjoy financial stability by working less and having great savings.
                                                </div>
                                                <div className=''>
                                                    <a href="#" className="btn btn-primary">Read More</a>
                                                </div>
                                            </div>

                                            <br />
                                        </div>
                                        <div className="e-card playing text-center col-12 col-md-6 col-lg-4 mb-3">
                                            <div className="image " >
                                                <img src={marrige} alt="" className='text-center' />
                                            </div>
                                            <div className="wave" />
                                            <div className="wave" />
                                            <div className="wave" />
                                            <div className="infotop">
                                                <h5>
                                                    LOVE & MARRIAGE
                                                </h5>
                                                <div className="name">By determining the perfect match of the couple by an astrologer would lead to success in the marriage relationship. astrocall provides such service online in a digital world which saves time as best advice is offered from astrologers.</div>
                                                <div className=''>
                                                    <a href="#" className="btn btn-primary">Read More</a>
                                                </div>
                                            </div>

                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true" />
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true" />
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </section >


            <section className='banner-slide mb-3'>
                <div className="container-fluid mt-3">
                    <h2 className='text-center'>OUR ASTROLOGERS</h2>
                    <div id="cardCarousel" className="carousel carousel-dark slide" data-bs-ride="carousel">
                        {/* Indicators/dots */}
                        <div className="carousel-indicators " style={{ marginBottom: '-10px' }}>
                            <button type="button" data-bs-target="#cardCarousel" data-bs-slide-to={0} className="active " aria-current="true" aria-label="Slide 1" />
                            <button type="button" data-bs-target="#cardCarousel" data-bs-slide-to={1} aria-label="Slide 2" />
                            {/* <button type="button" data-bs-target="#cardCarousel" data-bs-slide-to={2} aria-label="Slide 3" /> */}
                        </div>
                        {/* Carousel items */}
                        <div className="carousel-inner ">
                            <div className="carousel-item active ">
                                <div className="card-wrapper container d-flex  justify-content-around">
                                    <div className="row gx-5">

                                        <div className=" col-12 col-md-6 mb-3 col-lg-4"  >
                                            <div className="card">
                                                <div className="card-border-top">
                                                </div>
                                                <div className="img text-center">
                                                    <img src="/images/astro1.png" alt="" />
                                                </div>
                                                <span> Acharya Shankar Lal</span>
                                                <p className="job">
                                                    <marquee width="50%" direction="right" height="100px" scrollamount="8">
                                                        Ayurved
                                                    </marquee>
                                                </p>
                                                <div className=''>
                                                    <Link to={'/astrologers'} className="astro-click btn btn-primary">
                                                        Get Started
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" col-12 col-md-6 mb-3 col-lg-4"  >
                                            <div className="card">
                                                <div className="card-border-top">
                                                </div>
                                                <div className="img text-center">
                                                    <img src="/images/astro2.png" alt="" />
                                                </div>
                                                <span> Acharya Kishen Ji</span>
                                                <p className="job">
                                                    <marquee width="50%" direction="right" height="100px" scrollamount="8">
                                                        Astrologer
                                                    </marquee>
                                                </p>
                                                <div className=''>
                                                    <Link to={'/astrologers'} className="astro-click btn btn-primary">
                                                        Get Started
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" col-12 col-md-6 mb-3 col-lg-4"  >
                                            <div className="card">
                                                <div className="card-border-top">
                                                </div>
                                                <div className="img text-center">
                                                    <img src="/images/astro3.png" alt="" />
                                                </div>
                                                <span> Acharya Harsh Gautam</span>
                                                <p className="job">
                                                    <marquee width="50%" direction="right" height="100px" scrollamount="8">
                                                        Vedik Astrology
                                                    </marquee>
                                                </p>
                                                <div className=''>
                                                    <Link to={'/astrologers'} className="astro-click btn btn-primary">
                                                        Get Started
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item  ">
                                <div className="card-wrapper container d-flex  justify-content-around">
                                    <div className="row ">
                                        <div className=" col-12 col-md-6 mb-3 col-lg-4"  >
                                            <div className="card">
                                                <div className="card-border-top">
                                                </div>
                                                <div className="img text-center">
                                                    <img src="/images/astro4.png" alt="" />
                                                </div>
                                                <span>Acharya Dinesh Sharma</span>
                                                <p className="job">
                                                    <marquee width="50%" direction="right" height="100px" scrollamount="8">
                                                        Astrologer
                                                    </marquee>
                                                </p>
                                                <div className=''>
                                                    <Link to={'/astrologers'} className="astro-click btn btn-primary">
                                                        Get Started
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>

                                        <div className=" col-12 col-md-6 mb-3 col-lg-4"  >
                                            <div className="card">
                                                <div className="card-border-top">
                                                </div>
                                                <div className="img text-center">
                                                    <img src="/images/astro5.png" alt="" />
                                                </div>
                                                <span> Acharya Jaydeep Sharma</span>
                                                <p className="job">
                                                    <marquee width="50%" direction="right" height="100px" scrollamount="8">
                                                        Astrologer
                                                    </marquee>
                                                </p>
                                                <div className=''>
                                                    <Link to={'/astrologers'} className="astro-click btn btn-primary">
                                                        Get Started
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" col-12 col-md-6
                                         mb-3 col-lg-4"  >
                                            <div className="card">
                                                <div className="card-border-top">
                                                </div>
                                                <div className="img text-center">
                                                    <img src="/images/astro6.jpg" alt="" />
                                                </div>
                                                <span> Om Prakash Shastri</span>
                                                <p className="job">
                                                    <marquee width="50%" direction="right" height="100px" scrollamount="8">
                                                        Vashtu Shastra
                                                    </marquee>
                                                </p>
                                                <div className=''>
                                                    <Link to={'/astrologers'} className="astro-click btn btn-primary">
                                                        Get Started
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* Controls */}
                        <button className="carousel-control-prev" type="button" data-bs-target="#cardCarousel" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true" />
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#cardCarousel" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true" />
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </section>



            {/* <section className='horoscope-section mt-3 mb-5'>
                <div className="container">
                    <HoroscopeTab />
                </div>
            </section> */}

            {/* <section className='astrologers-section mt-3'>
                <div className="container">
                    <div className="astro-container">
                        <div className="box">
                            <img src="/images/astro_indu.png" className='img-fluid' />
                            <span>astrologer indu</span>
                        </div>
                        <div className="box">
                            <img src="/images/astro_ankush.png" className='img-fluid' />
                            <span>astrologer ankush</span>
                        </div>
                        <div className="box">
                            <img src="/images/astro_deepak.jpg" className='img-fluid' />
                            <span>astrologer deepak</span>
                        </div>
                        <div className="box">
                            <img src="/images/astro_indu.png" className='img-fluid' />
                            <span>astrologer indu</span>
                        </div>
                        <div className="box">
                            <img src="/images/astro_ankush.png" className='img-fluid' />
                            <span>astrologer ankush</span>
                        </div>
                        <div className="box">
                            <img src="/images/astro_deepak.jpg" className='img-fluid' />
                            <span>astrologer deepak</span>
                        </div>
                        <div className="box">
                            <img src="/images/astro_indu.png" className='img-fluid' />
                            <span>astrologer indu</span>
                        </div>
                        <div className="box">
                            <img src="/images/astro_ankush.png" className='img-fluid' />
                            <span>astrologer ankush</span>
                        </div>
                    </div>
                </div>
            </section> */}


            <section className='text-sec mt-2'>
                <div className="container-fluid ">
                    <div className="row " >
                        <div className="col-md-12">
                            <h4 className=" text-center pt-3">WHY ASTROLOGY ?</h4>
                            <h6 className=" text-center">Astrology reveals the will of the God</h6>
                            <div className="content-about ">
                                <p className="">Astrology is a predictive science with its own sets of methods, claims and findings that have forever inspired and allowed people with insights into different aspects of their life. Astrology, with its wows and hows, is contentful and approving enough to make people a believer of the same. And thankfully, it continues to do so despite the world shifting bases from what they believe in and what they don’t.</p> <br />
                                <p className="content-about ">If one has to go into the technicalities of astrology, it is the study of different cosmic objects, which are usually stars and planets, that have an effect on the lives of the people. You must be aware that there are as many as 8 planets in the solar system. However, If I ask an online astrologer near me about the planets in astrology, they will tell me that there are as many as 9 planets in astrology also called Navagrahas. And surprisingly, the planet Earth, in astrology, is not counted among the nine planets.</p> <br />
                                <p className="content-about">The 9 planets in astrology are Sun (Surya), Moon (Chandra), Mars (Mangala), Mercury (Budha), Jupiter (Brihaspati), Venus (Shukra), Saturn (Shani), Rahu (north node of the moon), and Ketu (south node of the moon).</p> <br />
                                <p className="content-about">It all depends on which houses these planets are sitting in. If you ever had the opportunity to talk to an astrologer online, then s/he must have told you about houses in astrology and the movements of planets in the same. There are as many as 12 houses in Kundli. And all of these houses represent one thing or the other. You can check your free kundali online.</p>
                                <br />
                                <p className="content-about">For example, the first house, which any online astrology consultation would tell you is also the ascendant of the person, is the house of self. It represents a person’s personality and physical traits. Similarly, the fifth house in the Vedic horoscope, also known as Putra Bhava is the house of creativity, playfulness, joy, pleasure, and romance. If, for example, a good planet, like Jupiter, is camping in the fifth house, your love life will excel. Likewise, if Rahu is camping in the same house, then you might feel the need for online astrology consultation to deal with the woes.sss</p>
                                <br />
                                <h5>Online Astrology Consultation &amp; Services</h5>
                                <p className="content-about">The online footprint of things and people has grown over the years. And AstroCall, as a brand, is using the best of it to cater online astrology services to anyone and everyone across the length and breadth of the globe. AstroCall, over the years, has nurtured as a community of the best astrologers who have their expertise in Vedic Astrology, tarot card reading, Vastu Shastra, numerology, Reiki healing, Palmistry, and many more subjects.</p> <br />
                                <p className="content-about">The motive behind providing astrology predictions online is simply helping people save time, money, and pain to find astrologers in the hustle and bustle of the city lights. Besides, to ensure credibility, AstroCall has, over the years, worked extensively to add value to customer service. And a good chunk of credit for this goes to the astrologers who work for 100% customer satisfaction using their knowledge to deliver impeccable astrology consultancy.</p> <br />
                                <h5>Online Astrologer</h5>
                                <p>A huge chunk of credit for what we are today goes to the force of online astrologers we have got on board. Astrologers come from different walks of life and only the best, who has a piece of extensive knowledge in their field, make it to this platform for your convenience. As the Astrologers work with us, they are scrutinized in the form of ratings that the customers give them. The better the rating, the better the service. Talking about service, there are multiple ways to avail the same. Either you can talk to the astrologer over a call or simply chat with them at your convenience. And guess what, the first chat with an astrologer can be availed at a 50% discount.</p> <br />
                                <p>
                                    The astrologers, we have on board, understand the essence of the 5000-year old science and try their best to do justice with it. Besides sharing their knowledge with you, they also share videos, write up and other rich content with us that is available across our social media pages and in the blog section of our website. These writings on Muhurats, upcoming festivals, online astrology prediction will quench the thirst of your pious soul for online astrology knowledge.
                                </p>
                                <br />
                                <h5>Stay Updated With Daily Horoscope Predictions &amp; Zodiac Signs</h5>
                                <p>We as a brand are new in the online astrology consultation arena but, at the same time, also empowered to offer our visitors the best invariably. So within a short span of time, we have created a space to offer even the minutest details on astrological elements like transits, astrology daily horoscope for different zodiac signs, updates on upcoming auspicious dates, and much more.</p>
                                <p>While astrology's daily horoscope allows one an opportunity to realign their day as per what the stars have for them in the box, having knowledge of muhurats, among other astrology services, on the other hand, ensures you are well updated on the best times of the day. So much information under one roof? Well, that’s what our purpose has been over the years and we continue to refine it.</p>
                                <br />
                                <h5>Why Choose Our Astrology Experts?</h5>
                                <p>In a nutshell, AstroCall and our experts could be your partner and guide when it comes to astrology. Be it a question on horoscope or numerology, tarot card reading or even connecting you with a foreign astrologer, we have practitioners to answer all your starry queries.</p>
                                <p>At last, what do you need to do to get in touch with our astrologers? Simply, login to the AstroCall app, and guess what? There is a free astrology online session waiting for you. Once you have finished your free astrology predictions session, and you want to stay with us for longer (which you would) then you can recharge your wallet, and connect with live astrologers to receive instant astrology consultation.</p>
                                <p>The best way to choose the best astrologers is to select the category and choose the top astrologers as per the ratings given to them by people like you. One can completely trust our service to be of supreme quality. And just in case you bump into a nag during astrology online consultation, you will be heard by our customer service team invariably.</p>
                                <p>AstroCall connects you with the best astrologer in India. If want to get the best astrological consultation from one of the best Indian astrologers online, search for the phrase “best astrologer near me” on Google, and you will find Indian astrologers online. AstroCall connects you with the best astrologers in India, the USA, Canada, Australia or we can say that you can connect with the best astrologers in the world by using the AstroCall website and App. If you are looking for the best astrologers in Noida, Delhi/NCR, Gurgaon, etc., AstroCall is the best online platform for you. We have the best astrologers online for marriage, career, health, etc. AstroCall is your ultimate destination if you want to&nbsp;
                                    <Link to={'#'}>
                                        talk to astrologer online,
                                    </Link>
                                    <Link to={'#'}>
                                        chat with astrologers,
                                    </Link>
                                    <Link to={'#'}>
                                        check free kundali,
                                    </Link>
                                    <Link to={'#'}>
                                        match making,
                                    </Link>
                                    daily horoscopes, gems, and rudraksha shopping, etc.</p>
                            </div>
                        </div>
                    </div>

                    {/* <div className="row about-row mt-5 pt-2">
                        <div className="col-md-12">
                            <h4 className="h1 text-center">WHY ASTROCALL</h4>
                            <div className="content-about">
                                <p className="py-2 px-4">AstroCall is an excellent online astrology app. Talk to our prolific astrologer on the phone and find a solution to all your worries by looking at your future. With AstroCall, you can access accurate astrological predictions from leading astrologers in India. Get the best predictions related to marriage, love life, or work via a phone call, face-to-face conversation, video call, or report.<br />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row about-row mt-5 pt-2">
                        <div className="col-md-12">
                            <h4 className="h1 text-center">BENEFITS OF ASTROCALL APPLICATION</h4>
                            <div className="content-about">
                                <p className="py-2 px-4">
                                </p><li className="px-4" >The mobile application could resolve all sorts of queries as they could find one of the best astrologers.</li>
                                <li className="px-4" >Through audio and chat facilities, astrologers could predict by looking towards Kundli and would try to find the best possible solution. </li>
                                <li className="px-4" > The application is transparent and secured as it keeps all types of information confidential.</li >
                                <li className="px-4" > It renders 24 * 7 support services. </li >
                                <p />
                                <p className="py-2 px-4">
                                    Therefore, the AstroCall application is designed in such a manner that users could get all answers related to their love life, career, health over the chat, audio call, or through a report in a presentable manner.
                                </p>
                            </div >
                        </div >
                    </div > */}
                </div >
            </section>
            <section className='text-sec1 '>
                <div className="container-fluid ">
                    <div className="row " >
                        <div className="col-md-12 mt-4">
                            <h4 className=" text-center">FAQ'S ABOUT ASTROLOGY</h4>
                            <h6 className=" text-center">Why Is Astrology So Accurate?</h6>
                            <div className="content-about">
                                <p className="">First of all, the credit for astrology and the predictions we make here being so accurate goes to the 100s of learned and astrologically-versed astrologers we have on board. And to answer what makes astrology so accurate, then it is the pure knowledge of our astrologers about the planets, planetary configurations, constellations and learning of how they influence human life and behaviour as they move, shift or even stay still. Astrology is not some random guesswork but science that entails scientific principles that were sketched by learned Gurus 1000s of years ago and have not changed to date. These gurus studied the movement of planets and how their aura influences the life of people on earth. The antiquity of astrology is revealed from the fact that millions of people continue to stay connected with it due to the accuracy it has to offer. Astrology has changed the lives of millions of people through us and many other astrologers who continue to share their knowledge of astrology one prediction at a time.</p>
                                <br />
                                <h5>Why Should You Choose AstroCall For An Astrology Horoscope?</h5>
                                <p>From 4.8/5 Google Rating, 5/5 Facebook Rating to appreciation across various other platforms, AstroCall, over the years, has garnered a wide user base that is nothing but satisfied with all the astrology related material we have to offer. The process of recruiting the astrologers on the AstroCall platform is really stringent and only the best astrologers can make it to the platform only to ensure you're getting the best service each and every time you get along with us.</p>
                                <p>
                                    The services we are talking about range across online Kundli matching, horoscope predictions, daily horoscope, weekly horoscope, numerology, tarot reading, shopping, free blogs, and many more services.
                                </p>
                                <p>
                                    Moreover, in case you bump into any sort of problem, you can directly connect with the CEO to register the same with us. Providing exceptional service is our main motto and we ensure that is achieved whatsoever.
                                </p>
                                <br />
                                <h5>Is Astrology Prediction True?</h5>
                                <p>Many people have misconceptions that an astrologer can tell them what exactly is going to happen with them in the future or next moment, which is a wrong way to think about astrology. Astrology predictions are based on planetary movements. A planet moves from one house to another, one zodiac sign to another, etc. These moments by plantes can either be good or unfavourable for the native. For example, if say the planet Mercury is camping in a friendly zodiac sign, the outcomes are to be good for the native. Meanwhile, if the mercury is camping in an unfriendly zodiac sign, the outcome can be critical. An astrologer, based on calculations, notes down such shifts and traits and based on the same share with you if your upcoming time would be good or tough. If you think this way, astrology predictions ought to be true. Accurate predictions are possible when you seek to know about things like upcoming shubh muhurats, whether you are manglik or not?, etc.</p> <br />
                                <h5>How Can Online Astrology Help Me In Predicting The Future?</h5>
                                <p>Online astrology can be of two types. One, you can visit a website and find astrology tools available on the same. Then all you need to do is enter some details like your date of birth, time of your birth, place of birth, etc. and you will have for yourself a contentful report describing you better than anyone else ever would. Meanwhile, the other way around is to directly get in touch with the astrologers (over a call or chat) we have on board to get the same information in a much detailed manner. Online astrology may look modern but when it comes to making predictions about your future, the old school way of calculating the position of the stars and planets is used to provide you the most accurate results. Simply say, online astrology is just a change of mode of communication between an astrologer and the one who seeks to know about their past, present and future. However, the ways to come to these answers is still old school and one one of the most authentic one.</p>
                                <br />
                                <h5>What Kind Of Questions Can I Ask To Online Astrologers?</h5>
                                <p>Until you are being respectful and authentic about your queries, there isn't a limit to what you can chat with an astrologer and what you can’t. Astrology has an answer for literally anything and everything. Starting right from your career to your love life or health, astrology can predict endless queries. You can trust us when we say our astrologers have been inundated with some of the most difficult questions, which they have answered with ease.</p>
                                <p>Are you a manglik? An astrologer can help you find that. Or what the shubh muhurat for getting married? Or what are the chances of you improving your financial position in the years to come? Our astrologers can answer all such questions by simply calculating the position of the planets and combining them with numerous factors like your nakshatra, zodiac signs, houses in astrology and more.</p>
                                <br />
                                <h5>What Is The Duration For Online Astrology Consultation?</h5>
                                <p>Astrology has no limits and nor do we. We have so many astrologers on board with whom you can connect and ask questions to. They would help you with all your queries without any time limit intact.</p>
                                <br />
                                <h5>Can Astrology Predictions Be Changed?</h5>
                                <p>Astrological predictions may change in several cases:</p>
                                <p>The first is when an astrologer unintentionally makes a mistake in locating the position of the planets. In such a case, the predictions can go wrong.</p>
                                <p>Secondly, if you have provided the wrong information, especially the date and time of your birth, then astrology predictions are subject to change.</p>
                                <p>The third situation when astrology predictions can go wrong is when you try to change yourself for good or bad. Many of you must be thinking that how an astrological prediction can change when it is mostly based on the movement of planets (which does not change)? Well, there is more to it. Every planet rules some good traits and bad traits. And it’s your deeds that decides which traits of the planet will become powerful. So for example, if you are a careless person as of now with Mercury as your ruling planet, you are unable to gain the good effects of Mercury. However, if you try to change yourself with time, the good traits of Mercury will eventually reflect in your chart and personality thus changing the astrology prediction.</p>
                                <br />
                                <h5>Can Astrology Predict About Lottery Winning?</h5>
                                <p>Astrology can’t predict if you will win a lottery today or not but it can surely help you predict what are your chances to win a lottery. Also, numerology is another form of prediction that can help you with lucky numbers prospects, which can eventually help you decide what number you can go with to get lucky with winning a lottery.</p> <br />
                                <h5>What Benefits Can I Expect If I Avail Astrology Services?</h5>
                                <p>First of all, if you are new to our platforms, you get a flat 50% discount on your first prediction. So that’s some savings. Secondly, using the insights provided by our astrologers about your future or the present self, you can be careful in terms that will help you deal with major problems, which could be finance-related, career-related, love related etc.</p>
                                <p>By seeing your astrological chart, an astrologer can not only predict about you but also about your behaviour towards your family, your relatives, your colleagues and more. Learning about these behaviours can help you better present yourself in front of people that matter. This way, astrology can positively help you improve your personality among uncountable goods it can do to you. For more details about astrology and future predictions, you can talk to astrologer.</p> <br />
                                <h5>Which app is best for kundli?</h5>
                                <p>AstroCall is one of the best platforms to get an online kundli for yourself. The exhaustive and free kundli available on AstroCall is prepared by the best and the most learned astrologers of AstroCall and is available in multiple languages for your convenience. The online kundli of AstroCall covers all dimensions of your life from past, present and future and based on these dimensions allow you suggestions that you can adopt into your life for a better future ahead. In the online Kundli, we have various sections that highlight the good, bad and neutral Dashas that lie ahead of you and what you must do during these Dasha periods to get the best of the different phases. The kundli also has as many as 20 charts, remedies and so much more that will allow you a 360-degree view of your life. So without a doubt, you can use AstroCall for the most authentic kundli. </p>
                                <br />
                                <h5>How reliable is the AstroCall app?</h5>
                                <p>Interestingly, the AstroCall app is made in such a way that it helps you with all your astrology needs. Apart from the main Talk to astrologers and Call to astrologers features, where the best astrologers from across the world are at your service, the AstroCall app also has many free features that a user can enjoy. These features include Free live sessions on the app through which you can talk to astrologers for free. AstroCall also has daily, weekly, monthly and yearly horoscopes prepared by expert astrologers that help you decode your future in advance. Then, AstroCall further has Free kundli, Free matchmaking and more such features that again are prepared by expert astrologers and come in handy for you any time of the day. Apart from this, if you need any kind of astrology products such as Rudraksha, Yantras, Gemstones, Reiki healing, etc. such products are too available on the app. So AstroCall is a one-stop destination for all your astrology needs and thus very reliable. </p>
                                <br />
                                <h5>How much does AstroCall cost?</h5>
                                <p>Simply put, AstroCall suits your budget. And if you consider the First Chat Free feature, then it will definitely suit your budget. On AstroCall, a user can get services at as low as Rs 10 per minute to Rs 250 per minute. The astrologers on AstroCall charge as per their experiences, both in personal life and their time on the app. So it doesn’t mean an astrologer who is charging low is not good. It could simply mean that he/she may have just started on the app and thus want to keep his/her price affordable for the user. Interestingly, some astrologers deliberately keep their consultation prices low as their prime motive is to only help others with their talent. In a nutshell, you will find both affordable and not so budget-friendly astrologers on AstroCall but at the end of the day, all of them are best at what they do as they have been scrutinised by the expert astrologers before they make it to the app.</p>
                                <br />
                                <h5>Can I trust astrology apps?</h5>
                                <p>Astrology apps have become increasingly popular in recent years, with AstroCall at the helm. Used by more than 3-crore people already, AstroCall has the top astrologers on board who you can consult for accurate predictions about marriage, career, relationship, and more. These astrologers are hired after five rounds of interviews, hence are best at what they do. Astrology apps also offer a variety of other features, such as daily horoscopes, personalized birth chart readings, etc. and all of this is 100% genuine, prepared by expert astrologers. In addition, astrology apps such as AstroCall even offer live consultations with professional astrologers, and that too for FREE. You can ask questions to astrologers and get instant solutions to your problems.</p>
                            </div>
                        </div>
                    </div >
                </div>
            </section>
            <section className='text-sec '>
                <div className="container-fluid ">
                    <div className="row  ">
                        <div className="col-md-12 mt-4">
                            <h4 className=" text-center">WHY ASTROCALL</h4>
                            <div className="content-about">
                                <p className="py-2 px-4">AstroCall is an excellent online astrology app. Talk to our prolific astrologer on the phone and find a solution to all your worries by looking at your future. With AstroCall, you can access accurate astrological predictions from leading astrologers in India. Get the best predictions related to marriage, love life, or work via a phone call, face-to-face conversation, video call, or report.<br />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-12">
                            <h4 className=" text-center">BENEFITS OF ASTROCALL APPLICATION</h4>
                            <div className="content-about">
                                <p className="">
                                </p><li className="" >The mobile application could resolve all sorts of queries as they could find one of the best astrologers.</li>
                                <li className="" >Through audio and chat facilities, astrologers could predict by looking towards Kundli and would try to find the best possible solution. </li>
                                <li className="" > The application is transparent and secured as it keeps all types of information confidential.</li >
                                <li className="" > It renders 24 * 7 support services. </li >
                                <p />
                                <p className="">
                                    Therefore, the AstroCall application is designed in such a manner that users could get all answers related to their love life, career, health over the chat, audio call, or through a report in a presentable manner.
                                </p>
                            </div >
                        </div >
                    </div >
                </div >
            </section>
            {/* <div id="videoCarousel" class="carousel slide mt-5" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="row">
          <div class="col-md-4 vid-slider-box">
            <div class="owl-video">
              <iframe width="70%" height="315" src="https://www.youtube.com/embed/77UDMPNJ9us" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <div class="text-content">
                <h4 class="name">Anshul Khandelwal</h4>
                <div class="degn">Software Engineer at Facebook</div>
                <div class="cmp">California, US</div>
              </div>
              
            </div>
          </div>
          <div class="col-md-4 vid-slider-box">
            <div class="owl-video">
              <iframe width="70%" height="315" src="https://www.youtube.com/embed/0JTva5Q2eWA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <div class="text-content">
                <h4 class="name">Acharya Harsh Gautam</h4>
                <div class="degn">Vedik Astrologer</div>
              </div>
            </div>
          </div>
          <div class="col-md-4 vid-slider-box">
            <div class="owl-video">
              <iframe width="70%" height="315" src="https://www.youtube.com/embed/_hb3KztTB34" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <div class="text-content">
                <h4 class="name">Acharya Surendra Shastri</h4>
                <div class="degn">Astrologer</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      

    </div>

    <div class="carousel-controls">
      <button class="carousel-control-prev" type="button" data-bs-target="#videoCarousel" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#videoCarousel" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div> */}


        </>
    )
}

export default Home