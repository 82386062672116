import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import PageBanner from '../../Utility/PageBanner'
import plan from '../../../Horoimg/Horos/2.png'
import axios from 'axios'


const Astromall = () => {
    const [FilterData, SetFilterData] = useState()
    const astromall = async () => {
        try {
            const res = await axios.get('https://astrocall.live/api/astromall');
            if (res) {
                const filteredData = res?.data?.data.filter((category) => category.categoryName);
                SetFilterData(filteredData);
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        astromall()
    }, [])

    return (
        <>
            <section className='banner'>
                <div className="container-fluid ">
                    <div className="page-img-banner  ">
                        <img src={plan} className='img-fluid' />
                    </div>
                </div>
            </section>
            <div className="astrologers-list astromall-page">
                <div className="container">
                    {/* <PageBanner img={'/images/page/astromall.png'}/> */}
                    <div className="row g-3">
                        <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                            <div className="bs" >
                                <Link to='/Gemstone' key={FilterData ? FilterData[0]?.id : ''}>
                                    <div className="astro-box">
                                        <div className="img-box">
                                            <img src="/images/astromall/gemstone.jpg" />
                                            {/* <img src={FilterData ? FilterData[0]?.categoryImage : ''} /> */}
                                        </div>
                                        <div className="text ms-3">
                                            <h6>{FilterData ? FilterData[0]?.categoryName : ''}</h6>
                                            <span>{FilterData ? FilterData[0]?.content.substring(0, 100) + "..." : ''}</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                            <div className="bs" key={FilterData ? FilterData[1]?.id : ''}>
                                <Link to='/OnlinePuja'>
                                    <div className="astro-box">
                                        <div className="img-box">
                                            <img src="/images/astromall/puja.jpg" />
                                            {/* <img src={FilterData ? FilterData[1]?.categoryImage : ''} /> */}
                                        </div>
                                        <div className="text ms-3">
                                            <h6>{FilterData ? FilterData[1]?.categoryName : ''}</h6>
                                            <span>{FilterData ? FilterData[1]?.content.substring(0, 100) + "..."  : ''}</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                            <div className="bs" key={FilterData ? FilterData[2]?.id : ''}>
                                <Link to='/astromall/books'>
                                    <div className="astro-box">
                                        <div className="img-box">
                                            {/* <img src={FilterData ? FilterData[2]?.categoryImage : ''} /> */}
                                            <img src="/images/astromall/book.png" />
                                        </div>
                                        <div className="text ms-3">
                                            <h6>{FilterData ? FilterData[2]?.categoryName : ''}</h6>
                                            <span>{FilterData ? FilterData[2]?.content.substring(0, 100) + "..." : ''}</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                            <div className="bs" key={FilterData ? FilterData[3]?.id : ''}>
                                <Link to='/astromall/rudraksha'>
                                    <div className="astro-box">
                                        <div className="img-box">
                                            {/* <img src={FilterData ? FilterData[3]?.categoryImage : ''} /> */}
                                            <img src="/images/astromall/rudraksha.jpg" />
                                        </div>
                                        <div className="text ms-3">
                                            <h6>{FilterData ? FilterData[3]?.categoryName : ''}</h6>
                                            <span>{FilterData ? FilterData[3]?.content.substring(0, 100) + "..." : ''}</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Astromall