import React, { useContext, useEffect, useState } from 'react'
import './Plans.css'
import plan from '../../../Horoimg/Horos/4.png'
import { Link, useNavigate } from 'react-router-dom'
import { CommonContextData } from '../../../Context/CommonContext'


const Plans = () => {
    const [Productinfodata, setProductinfodata] = useState('')
    const navigate = useNavigate();

    const handlelclick = (type) => {
        navigate(`/purchase?PR=${type}`)
    }

    return (
        <>
            <section className='banner'>
                <div className="container-fluid ">
                    <div className="page-img-banner  ">
                        <img src={plan} className='img-fluid' />
                    </div>
                </div>
            </section>
            <div className="about-page">
                <div className="container">
                    <section className='plans-banner'>
                        <div className="pricingTable row g-3 justify-content-center">
                            <div className="col-12 col-sm-6 col-md-4">
                                <div className="plan ">
                                    <div className="inner">
                                        <span className="pricing">
                                            <span>
                                                ₹50<small>/ m</small>
                                            </span>
                                        </span>
                                        <p className="title">Small</p>
                                        <p className="info">This plan is for those who have a team already and running a large business.</p>
                                        <ul className="features">
                                            <li>
                                                <span className="icon">
                                                    <svg height={24} width={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 0h24v24H0z" fill="none" />
                                                        <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                                                    </svg>
                                                </span>
                                                <span><strong>Unlimited </strong> Validity</span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg height={24} width={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 0h24v24H0z" fill="none" />
                                                        <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                                                    </svg>
                                                </span>
                                                <span>24/7 <strong> Support</strong></span>
                                            </li>
                                            {/* <li>
                                            <span className="icon">
                                                <svg height={24} width={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0 0h24v24H0z" fill="none" />
                                                    <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                                                </svg>
                                            </span>
                                            <span>File sharing</span>
                                        </li>  */}
                                        </ul>
                                        <div className="action">
                                            <a className="button" href="#">
                                                {/* <button type='button' className='c-btn ' onClick={()=>{handlelclick(); (Productdata[0])}}  > Purchase Now </button> */}
                                                <button type='button' className='c-btn' onClick={() => { handlelclick("SMALL"); }}>Purchase Now</button>

                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4">
                                <div className="plan ">
                                    <div className="inner">
                                        <span className="pricing">
                                            <span>
                                                ₹100<small>/ m</small>
                                            </span>
                                        </span>
                                        <p className="title">Medium</p>
                                        <p className="info">This plan is for those who have a team already and running a large business.</p>
                                        <ul className="features">
                                            <li>
                                                <span className="icon">
                                                    <svg height={24} width={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 0h24v24H0z" fill="none" />
                                                        <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                                                    </svg>
                                                </span>
                                                <span><strong>Unlimited </strong> Validity</span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg height={24} width={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 0h24v24H0z" fill="none" />
                                                        <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                                                    </svg>
                                                </span>
                                                <span>24/7 <strong> Support</strong></span>
                                            </li>
                                        </ul>
                                        <div className="action">
                                            <a className="button" href="#">
                                                <button type='button' className='c-btn ' onClick={() => { handlelclick('MEDIUM'); }}  > Purchase Now </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4">
                                <div className="plan ">
                                    <div className="inner">
                                        <span className="pricing">
                                            <span>
                                                ₹200<small>/ m</small>
                                            </span>
                                        </span>
                                        <p className="title">Bronze</p>
                                        <p className="info">This plan is for those who have a team already and running a large business.</p>
                                        <ul className="features">
                                            <li>
                                                <span className="icon">
                                                    <svg height={24} width={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 0h24v24H0z" fill="none" />
                                                        <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                                                    </svg>
                                                </span>
                                                <span><strong>Unlimited </strong> Validity</span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg height={24} width={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 0h24v24H0z" fill="none" />
                                                        <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                                                    </svg>
                                                </span>
                                                <span>24/7 <strong> Support</strong></span>
                                            </li>
                                        </ul>
                                        <div className="action">
                                            <a className="button" href="#">
                                                <button type='button' className='c-btn ' onClick={() => { handlelclick('BRONZE');  }}  > Purchase Now </button>

                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4">
                                <div className="plan ">
                                    <div className="inner">
                                        <span className="pricing">
                                            <span>
                                                ₹300<small>/ m</small>
                                            </span>
                                        </span>
                                        <p className="title">Silver</p>
                                        <p className="info">This plan is for those who have a team already and running a large business.</p>
                                        <ul className="features">
                                            <li>
                                                <span className="icon">
                                                    <svg height={24} width={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 0h24v24H0z" fill="none" />
                                                        <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                                                    </svg>
                                                </span>
                                                <span><strong>Unlimited </strong> Validity</span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg height={24} width={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 0h24v24H0z" fill="none" />
                                                        <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                                                    </svg>
                                                </span>
                                                <span>24/7 <strong> Support</strong></span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg height={24} width={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 0h24v24H0z" fill="none" />
                                                        <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                                                    </svg>
                                                </span>
                                                <span>Unlimited<strong> Calls</strong></span>
                                            </li>
                                        </ul>
                                        <div className="action">
                                            <a className="button" href="#">
                                                <button type='button' className='c-btn ' onClick={() => { handlelclick('SILVER'); }}  > Purchase Now </button>

                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4">
                                <div className="plan ">
                                    <div className="inner">
                                        <span className="pricing">
                                            <span>
                                                ₹400<small>/ m</small>
                                            </span>
                                        </span>
                                        <p className="title">Gold</p>
                                        <p className="info">This plan is for those who have a team already and running a large business.</p>
                                        <ul className="features">
                                            <li>
                                                <span className="icon">
                                                    <svg height={24} width={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 0h24v24H0z" fill="none" />
                                                        <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                                                    </svg>
                                                </span>
                                                <span><strong>Unlimited </strong> Validity</span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg height={24} width={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 0h24v24H0z" fill="none" />
                                                        <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                                                    </svg>
                                                </span>
                                                <span>24/7 <strong> Support</strong></span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg height={24} width={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 0h24v24H0z" fill="none" />
                                                        <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                                                    </svg>
                                                </span>
                                                <span>Unlimited<strong> Calls</strong></span>
                                            </li>
                                        </ul>
                                        <div className="action">
                                            <a className="button" href="#">
                                                <button type='button' className='c-btn ' onClick={() => { handlelclick('GOLD');  }}  > Purchase Now </button>

                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4">
                                <div className="plan ">
                                    <div className="inner">
                                        <span className="pricing">
                                            <span>
                                                ₹500<small>/ m</small>
                                            </span>
                                        </span>
                                        <p className="title">PLATINUM</p>
                                        <p className="info">This plan is for those who have a team already and running a large business.</p>
                                        <ul className="features">
                                            <li>
                                                <span className="icon">
                                                    <svg height={24} width={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 0h24v24H0z" fill="none" />
                                                        <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                                                    </svg>
                                                </span>
                                                <span><strong>Unlimited </strong> Validity</span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg height={24} width={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 0h24v24H0z" fill="none" />
                                                        <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                                                    </svg>
                                                </span>
                                                <span>24/7 <strong> Support</strong></span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg height={24} width={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 0h24v24H0z" fill="none" />
                                                        <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                                                    </svg>
                                                </span>
                                                <span>File<strong> sharing</strong></span>
                                            </li>
                                            <li>
                                                <span className="icon">
                                                    <svg height={24} width={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 0h24v24H0z" fill="none" />
                                                        <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                                                    </svg>
                                                </span>
                                                <span>Unlimited<strong> Calls</strong></span>
                                            </li>
                                        </ul>
                                        <div className="action">
                                            <a className="button" href="#">
                                                <button type='button' className='c-btn ' onClick={() => { handlelclick('PLATINUM'); }}  > Purchase Now </button>

                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div class="col-12 col-sm-6 col-md-3">
                            <div class="inner holder">
                                <div class="hdng">
                                    <p>SILVER</p>
                                </div>
                                <div class="price mt-5">
                                    <p><b>₹300 </b></p>
                                </div>
                                <div class="info">
                                    <p>Unlimited Validity</p>
                                    <p>24/7 Support</p>
                                </div>
                                <div class="btn">
                                    <button className='c-btn'>Purchase Now</button>
                                </div>
                            </div>
                        </div> */}


                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default Plans