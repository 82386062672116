import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { CommonContextData } from '../../../../Context/CommonContext';

const ProductBuynow = () => {
    const { loginModal, setLoginModal, } = useContext(CommonContextData);
    const UserIddata = JSON.parse(sessionStorage.getItem('UserProfile'));
    const [UserId, SetUserId] = useState(UserIddata?.data?.data?.id)
    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };
    const query = useQuery();
    var slugdata = query?.get("PR");

    const [value, SetValue] = useState({
        'email': '',
        'acode': '',
        'land': '',
        'plot_no': '',
        'pincode': '',
        'address': '',
    })
    const [filterslugdata, setfilterslugdata] = useState()
    const [errors, setErrors] = useState({
        'email': ''
    })

    const Handlechange = (e) => {
        SetValue({
            ...value,
            [e.target.name]: e.target.value
        })
    }

    const Handlechangenumber = (e) => {
        let num = e.target.value.replace(/[^0-9.]/g, "")
        SetValue({
            ...value,
            [e.target.name]: num
        })
    }

    useEffect(() => {
        get_gemstone()
    }, [])

    const get_gemstone = async () => {
        try {
            const res = await axios.get('https://astrocall.live/api/gemstone');
            if (res) {
                setfilterslugdata(res?.data?.data.filter((item) => item.slug === slugdata));
            }
        } catch (error) {
            console.log(error)
        }
    }

    // const loadRazorpay = async () => {
    //     var options = {
    //         "key": "rzp_live_zWtXPYpzqDLpAH",
    //         "amount": (filterslugdata ? filterslugdata[0]?.price : '') * 100,
    //         //   "currency": "INR", 
    //         // "name": "NiceSnippets",
    //         // "description": "Test Transaction",
    //         // "image": "https://www.nicesnippets.com/image/imgpsh_fullsize.png",
    //         // "order_id": ordersdata.id,
    //         handler: async function (response) {
    //         },
    //     }
    //     const rzp1 = new window.Razorpay(options);
    //     rzp1.open();
    // }

    const loadRazorpay = async () => {
        if (!window.Razorpay) {
            console.error('Razorpay SDK not loaded');
            return;
        }
        var options = {
            "key": "rzp_live_zWtXPYpzqDLpAH",
            "amount": (filterslugdata ? filterslugdata[0]?.price : '') * 100,
            handler: async function (response) {
                console.log(response);
            },
        }
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    }

    const checkValidationErrors = () => {
        const newErrors = {};
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!value.email) {
            newErrors.email = 'Enter email address *';
        } else if (!emailPattern.test(value.email)) {
            newErrors.email = 'Not a valid e-mail address*';
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            loadRazorpay();
        }
    };

    return (
        <div className='container'>
            {filterslugdata && filterslugdata?.length > 0 ? (
                <div class="row ">
                    <div class="col-12" >
                        <div class="row box">
                            <div class="col-3">
                                <div class="left text-center">
                                    <img src={filterslugdata ? filterslugdata[0]?.image : ''} alt="" class="img-fluid" style={{ width: '200px', height: '200px', borderRadius: '10px' }} />
                                    <h3 class="text-center mt-2">{filterslugdata ? filterslugdata[0]?.name : ''}</h3>
                                    <p class="text-center d-inline-block">{filterslugdata ? filterslugdata[0]?.sub_title : ''}</p>
                                    <p class="text-center d-inline-block ml-3" style={{ fontSize: '22px', fontWeight: 'bold' }}>
                                        <img src={filterslugdata ? filterslugdata[0]?.image : ''} alt="" style={{ width: '15px', height: '20px', borderRadius: '0px', paddingBottom: '4px' }} />
                                        {filterslugdata ? filterslugdata[0]?.price : ''}/-
                                    </p>
                                </div>
                            </div>
                            <div class="col-8 section-profile">
                                <div class="right">
                                    <h3>{filterslugdata ? filterslugdata[0]?.name : ''}</h3>
                                    <p class="d-inline-block ">{filterslugdata ? filterslugdata[0]?.sub_title : ''}</p>
                                    <span class="ml-3" style={{ fontSize: '23px', fontWeight: 'bold' }}>
                                        <img src="" alt="" style={{ width: '15px', height: '20px', borderRadius: '0px', paddingBottom: '4px' }} />
                                        {filterslugdata ? filterslugdata[0]?.price : ''}/-
                                    </span>
                                </div>
                                <br />
                                <div class="row">
                                    <div class="col-8">
                                        <div class="form-group">
                                            <label for="email">Email
                                                {errors.email && <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }} className="error-message">{errors.email}</p>}
                                            </label>
                                            <input type="text" id="email" name='email' value={value?.email} onChange={Handlechange} class="form-control" />
                                            {/* <span id="email_err" class="text-danger checkout_error_field"></span> */}
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label for="Phone">Astro Code</label>
                                            <input type="text" id="acode" name='acode' value={value?.acode} onChange={Handlechange} class="form-control" />
                                            {/* <span id="astrocode_err" class="text-danger checkout_error_field"></span> */}
                                        </div>
                                    </div>
                                    <div>

                                        <div class="col-8 mt-2">
                                            <div class="form-group">
                                                <label for="email">Land Mark</label>
                                                <input type="text" id="land" name='land' value={value?.land} onChange={Handlechange} class="form-control" />
                                                {/* <span id="landmark_err" class="text-danger checkout_error_field"></span> */}
                                            </div>
                                        </div>
                                        <div class="col-4 mt-2">
                                            <div class="form-group">
                                                <label for="email">Plot No</label>
                                                <input type="text" id="plot_no" name='plot_no' value={value?.plot_no}
                                                    onChange={Handlechangenumber} class="form-control" />
                                                {/* <span id="plotno_err" class="text-danger checkout_error_field"></span> */}
                                            </div>
                                        </div>
                                        <div class="col-8 mt-2">
                                            <div class="form-group">
                                                <label for="email">Pin Code</label>
                                                <input type="text" id="pincode" name='pincode' value={value?.pincode}
                                                    onChange={Handlechangenumber} class="form-control" />
                                                {/* <span id="pincode_err" class="text-danger checkout_error_field"></span> */}
                                            </div>
                                        </div>
                                        <div class="col-8 mt-2">
                                            <div class="form-group">
                                                <label for="address">Address</label>
                                                <textarea name="address" id="address" value={value?.address} onChange={Handlechange} cols="30" rows="5" class="form-control"></textarea>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <a href="/gemstone" class="c-btn mx-2"><i class="fa fa-chevron-circle-left"></i> Back</a>
                                <button class="c-btn mx-2" onClick={() => { UserId ? checkValidationErrors() : setLoginModal(true) }}>Buy Now</button>

                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    )
}

export default ProductBuynow