import React, { createContext, useState } from 'react'
import { instance } from '../../api'

export const CommonContextData = createContext()
const CommonContext = ({ children }) => {
    const [dataList, setDataList] = useState([])
    const [loading, setLoading] = useState(false)
    const [IsLogin, setIsLogin] = useState(false)
    const [LoginUserId, setLoginUserId] = useState()
    const [userdata, setuserdata] = useState([])
    const [loginModal, setLoginModal] = useState(false)
   
    
    const getDataFun = async (endPoint) => {
        setLoading(true)
        try {
            const res = await instance.get(endPoint)
            if (res?.data?.status) {
                setDataList(res?.data?.data)
                setLoading(false)
            } else {
                setDataList([])
                setLoading(false)
            }
        } catch (error) {
            setDataList()
            console.log(error)
            setLoading(false)
        }
    }
    return (
        <CommonContextData.Provider value={{ getDataFun, dataList, loading, setLoading, IsLogin, setIsLogin, setLoginUserId, LoginUserId, userdata, setuserdata, setDataList, setLoginModal, loginModal}}>
            {children}
        </CommonContextData.Provider>
    )
}

export default CommonContext