
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyASjis3utekVsrWY6jVLRZ9QcHaZ-lKneM",
  authDomain: "astro4callapp.firebaseapp.com",
  projectId: "astro4callapp",
  storageBucket: "astro4callapp.appspot.com",
  messagingSenderId: "832921888782",
  appId: "1:832921888782:web:df2508918ef0e27fe8745f",
  databaseURL: "https://astro4callapp-default-rtdb.firebaseio.com/"
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app)

export { db, app };
