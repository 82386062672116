import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <section className="footer-section mt-3">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                <h5> <img src="/images/logo.png" /></h5>
                <p className='m-0 '>Astro Call provides all different kinds of services as it is one of the best apps to which top most astrologers are connected with it and render an instant solution to a user.</p>
                <div className="social-link mt-2">

                  <Link to={'https://www.instagram.com/astrocall.live?igsh=dGNmcGwwZjhoYjk0'} target='_blank'>
                    <i class="fa-brands fa-instagram"></i>
                  </Link>
                  <Link to={'https://www.facebook.com/people/AstroCall/100075982458518/'} target='_blank'>
                    <i class="fa-brands fa-facebook"></i>
                  </Link>
                  <Link to={'https://www.youtube.com/channel/UCEwC7-tDlmudTe4Ivj2NrYw'} target='_blank'>
                    <i class="fa-brands fa-youtube"></i>
                  </Link>
                  <Link to={'#'}>
                    <i class="fa-brands fa-x-twitter"></i>
                  </Link>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                <h5 className='title'>Corporate Info</h5>
                <div className="link-box">
                  <Link to={'#'} className='d-flex align-items-center'>
                    <i class="fa fa-arrow-right" />
                    <span>privacy policy</span>
                  </Link>
                  <Link to={'#'} className='d-flex align-items-center'>
                    <i class="fa fa-arrow-right" />
                    <span>disclaimer</span>
                  </Link>
                  <Link to={'#'} className='d-flex align-items-center'>
                    <i class="fa fa-arrow-right" />
                    <span>term & condition</span>
                  </Link>
                  <Link to={'#'} className='d-flex align-items-center'>
                    <i class="fa fa-arrow-right" />
                    <span>refund policy</span>
                  </Link>
                  <Link to={'#'} className='d-flex align-items-center'>
                    <i class="fa fa-arrow-right" />
                    <span>membership policy</span>
                  </Link>
                  <Link to={'#'} className='d-flex align-items-center'>
                    <i class="fa fa-arrow-right" />
                    <span>cencellation policy</span>
                  </Link>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                <h5 className='title'>quick Links</h5>
                <div className="link-box">
                  <Link to={'#'} className='d-flex align-items-center'>
                    <i class="fa fa-arrow-right" />
                    <span>privacy policy</span>
                  </Link>
                  <Link to={'#'} className='d-flex align-items-center'>
                    <i class="fa fa-arrow-right" />
                    <span>disclaimer</span>
                  </Link>
                  <Link to={'#'} className='d-flex align-items-center'>
                    <i class="fa fa-arrow-right" />
                    <span>term & condition</span>
                  </Link>
                  <Link to={'#'} className='d-flex align-items-center'>
                    <i class="fa fa-arrow-right" />
                    <span>refund policy</span>
                  </Link>
                  <Link to={'#'} className='d-flex align-items-center'>
                    <i class="fa fa-arrow-right" />
                    <span>membership policy</span>
                  </Link>
                  <Link to={'#'} className='d-flex align-items-center'>
                    <i class="fa fa-arrow-right" />
                    <span>cencellation policy</span>
                  </Link>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                <h5 className='title'>help & support</h5>
                <div className="link-box">
                  <Link to={'#'} className='d-flex align-items-center'>
                    <i class="fa fa-phone" />
                    <span>+91-9352825230</span>
                  </Link>
                  <Link to={'#'} className='d-flex align-items-center'>
                    <i class="fa fa-envelope" />
                    <span>contact@astrocall.live</span>
                  </Link>
                  <Link to={'#'} className='d-flex align-items-center'>
                    <i class="fa fa-map-marker" />
                    <span>Plot No. 22 Tirupati Vihar, </span>
                  </Link>
                  <span className='d-block ' style={{ marginLeft: '22px' }}>Patrakar Colony Road Mansarovar, Jaipur, Rajasthan 302020</span>
                </div>
              </div>
              <hr className='my-3' />
              <p className='text-center ' style={{ color: 'black', fontWeight: '600' }}>© Copyright 2021 by <Link to={'#'} className='text-primary'>Arustu Technology</Link>. All right Reserved</p>
            </div>
          </div>
        </div>
      </section>
      <button className="scroll-to-top" id="scrollToTopBtn" onClick={scrollToTop}>
        &#8679;
      </button>
    </>
  )
}

export default Footer