import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react'
import OTPInput from 'react-otp-input';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { toastifyError, toastifySuccess, } from '../Utility/Utility';
import PhoneInput from "react-phone-input-2";
import { CommonContextData } from '../../Context/CommonContext';
import 'react-phone-number-input/style.css'
import "react-phone-input-2/lib/bootstrap.css";
import logo from '../../Horoimg/logo1.png'
import defualtImage from '../../Horoimg/Horos/download.png'
import { Collapse } from 'react-bootstrap';


const Header = () => {

    const { setLoginUserId, userdata, setuserdata, loginModal, setLoginModal, astrologin, setastrologin } = useContext(CommonContextData);

    const str = sessionStorage.getItem('NameObject');
    const navigate = useNavigate();
    const location = useLocation();
    const dropdownRef = useRef(null);
    const page = location.pathname
    const onClick = () => setIsActive(!isActive);
    const pathSegments = location.pathname.split('/');
    const horoscope = pathSegments[pathSegments.length - 2];
    const astromall = pathSegments[pathSegments.length - 2];
    const [otpStatus, setOtpStatus] = useState(false);
    const [enteredOtp, setenteredOtp] = useState()
    const [isActive, setIsActive] = useState(dropdownRef);
    const [phone, setPhone] = useState("");
    const [timerOn, setTimerOn] = useState(false);
    const [expireOtp, setExpireOtp] = useState(true);
    const [sec, setSec] = useState("00");
    const [min, setMin] = useState("00");
    const [NameStatus, setNameStatus] = useState(false)
    const [NameObject, SetNameObject] = useState()
    const [LogOutStatus, SetLogOutStatus] = useState(false)
    const [show, setShow] = useState(false)
    const [UserProfile, SetUserProfile] = useState()
    const [open, setOpen] = useState(false);
    const handleToggle = () => setOpen(!open);

    const googleTranslateRef = useRef(null);

    // const OtpVerify = async (e) => {
    //     // console.log("click");
    //     const data = {
    //         Name: "Ram",
    //         DOB: "09/12/2002",
    //         city: "Jaipur",
    //         State: "Rajshthan",
    //     };
    //     SetNameObject(data.Name);
    //     if (enteredOtp === '123456') {
    //         setastrologin(true);
    //         setLoginUserId(enteredOtp);
    //         toastifySuccess("Successfully LogIn");
    //         sessionStorage.setItem('NameObject', data.Name);
    //         setNameStatus(true);
    //         setLoginModal(false);
    //     } else {
    //         toastifyError("Invalid OTP");
    //     }
    // }

    useEffect(() => {
        if (str?.length > 0) {
            setNameStatus(true);
            SetNameObject(str);
        }
    }, [str]);


    const LoginModal = () => {
        setLoginModal(true);
    }

    const logout = () => {
        setNameStatus(false)
        sessionStorage.removeItem('NameObject')
        sessionStorage.removeItem('UserProfile')
        navigate('/')
    }

    useEffect(() => {
        if (timerOn) { timer(60) };
    }, [timerOn])

    const timer = (remaining) => {
        let m = Math.floor(remaining / 60);
        let s = remaining % 60;
        m = m < 10 ? '0' + m : m;
        s = s < 10 ? '0' + s : s;
        setMin(m)
        setSec(s)
        // document.getElementById('timeRemain').innerHTML = m + ':' + s;
        remaining -= 1;

        if (remaining >= 0 && timerOn) {
            setTimeout(function () {
                timer(remaining);
            }, 1000);
            return;
        }
        setExpireOtp(false)
    }

    const Resendotp = (e) => {
        loginApi();
        timer(60);
        setExpireOtp(true)
    }

    const loginApi = async (e) => {
        setTimerOn(true)
        try {
            const res = await axios.post('https://astrocall.live/api/v1/customer-login', { contact: phone });
            if (res?.data?.success) {
                setOtpStatus(true);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const OtpVerify = async (e) => {
        try {
            const val = { "contact": phone, "otp": enteredOtp }
            const res = await axios.post('https://astrocall.live/api/v1/verify-otp', val);
            console.log(res, 'res')
            console.log(res.data.message, 'res')
            if (res.data.status) {
                toastifySuccess(res.data.message);
                setLoginModal(false);
                setTimerOn(false);
                SetNameObject(res?.data?.data?.name);
                // setuserdata(res?.data?.data)
                SetUserProfile(res);
                setLoginUserId(res?.data?.data?.user_id);
                sessionStorage.setItem('NameObject', res?.data?.data?.name);
                sessionStorage.setItem('UserProfile', JSON.stringify(res));
                // sessionStorage.setItem('UserProfile', res?.data?.data);
                setNameStatus(true);
            }
        } catch (error) {
            toastifyError("Invalid OTP")
        }
    }

    useEffect(() => {
        let intervalId = null;
        const checkGoogleTranslate = () => {
            if (window?.google && window?.google?.translate && window?.google?.translate?.TranslateElement?.InlineLayout) {
                clearInterval(intervalId);
                new window.google.translate.TranslateElement(
                    {
                        // pageLanguage: "en",
                        includedLanguages: 'en,hi',
                        layout: window?.google?.translate?.TranslateElement?.InlineLayout?.VERTICAL
                    },
                    googleTranslateRef?.current
                );
            }
        };
        intervalId = setInterval(checkGoogleTranslate, 100);
        return () => clearInterval(intervalId);
    }, []);


    return (
        <>

            <nav className="navbar navbar-expand-lg navbar-light ">
                <div className="container-fluid">
                    {/* <Link to={'/'} className='p-2 navbar-brand'>
                        <img src={logo} style={{ width: '50px' }} />
                    </Link> */}
                    {/* <span className='logo-text'>AstroCall</span> */}
                    <Link to={'/'} className='navbar-brand mx-4'>
                        <div className="d-flex align-items-center">
                            <img src={logo} style={{ width: '50px' }} />
                            <div className="anytime-logo-ntext mx-2">
                                <span className="logo-text">AstroCall</span>
                                <span className="logo-span d-block">Online Astrology Predictions</span>
                            </div>
                        </div>

                    </Link>
                    {/* <a className="navbar-brand" href="https://www.anytimeastro.com/?utm_source=astroweb&utm_campaign=site&utm_medium=AST5690_AST5561_RUNT">
                        <div className="d-flex align-items-center">
                            <img src="https://cdn.anytimeastro.com/dashaspeaks/web/content/anytimeastro/images/anytime-astro-logo-icon.svg" alt="Anytime Astro" className="img-fluid" width={53} height={53} />
                            <div className="anytime-logo-ntext ml-2">
                                <span className="anytime-logo-text">Anytime Astro</span>
                            </div>
                        </div>
                    </a> */}

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mx-auto mb-lg-0 header-menu">
                            <Link to={'/astrologers'}>
                                <li className="nav-item mt-1">
                                    <button className='header-btn' >
                                        <i class="fa fa-phone-volume mx-2"></i>
                                        Talk To Astrologer
                                    </button>
                                </li>
                            </Link>
                            <Link to={'/astrologers'}>
                                <li className="nav-item mt-1">
                                    <button className='header-btn' >
                                        <i class="fa fa-comments mx-2"></i>
                                        Chat With  Astrologer
                                    </button>
                                </li>
                            </Link>
                            <li className="nav-item mt-2 pt-1" >
                                <div ref={googleTranslateRef}></div>
                                {/* <select name="" id="" className='form-select'>
                                    <option value="">Eng</option>
                                    <option value="">Hindi</option>
                                </select> */}
                            </li>
                        </ul>
                        <div className="logo mt-1 me-auto">
                            {NameStatus ? (
                                <>
                                    <div >
                                        {/* <i className='fa fa-user head-i'>
                                        </i> */}
                                        <button type="button" className="btn btn-primary text-uppercase">{NameObject}</button>
                                        <span type="button" className=" dropdown-toggle dropdown-toggle" data-toggle="dropdown" onClick={() => { setShow(show ? false : true) }}></span>
                                        <div className="dropdown-menu show card-agency" style={{ display: show ? "block" : "none" }}>
                                            <div className="header-card bb">
                                                <fieldset className=''>
                                                    <legend style={{ margin: 'auto' }}>
                                                        <div className="header-img mt-3 text-center">
                                                            <img src={defualtImage} alt='' />
                                                        </div>
                                                    </legend>
                                                </fieldset>
                                                <div className="text-center ml-5 mt-2">
                                                    <h5 className="text-bold " style={{ fontSize: '14px', color: '#001f3f' }}>{NameObject}</h5>
                                                </div>
                                            </div>
                                            <Link to={'/Profile'} className='dropdown-item bb' onClick={() => { setShow(false) }} >
                                                <i className='fa fa-user ' ></i> &nbsp; Profile
                                            </Link>
                                            <Link to={'/History'} className='dropdown-item bb' onClick={() => { setShow(false) }}>
                                                <i className='fa fa-history ' ></i> &nbsp; History
                                            </Link>
                                            <Link to={'/History'} className='dropdown-item bb' onClick={() => { setShow(false) }}>
                                                <i className='fa fa-wallet ' ></i> &nbsp; My Wallet
                                            </Link>
                                            <Link onClick={() => { SetLogOutStatus(true); setShow(false) }} className='dropdown-item '>
                                                <i className='fa fa-sign-out ' ></i> &nbsp; Logout
                                            </Link>
                                            {/* <button className="dropdown-item" onClick={() => { SetLogOutStatus(true) }} >Logout</button> */}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className=' mt-1 ' onClick={() => { LoginModal(); }}>
                                        <div className='d-flex'>
                                            <div className="div">
                                                <i className='fa fa-user head-i'>
                                                </i>
                                            </div>
                                            <span className='header-sign'>
                                                <span>
                                                    Sign In
                                                </span>
                                            </span>
                                        </div>

                                    </div>
                                </>
                            )}
                        </div>
                        {/* <li className="nav-item dropdown has-megamenu" style={{ listStyle: 'none' }}>
                            <button
                                className="nav-link dropdown-toggle"
                                type="button"
                                onClick={handleToggle}
                                aria-controls="navbarCollapse"
                                aria-expanded={open}
                                aria-label="Toggle navigation"
                                style={{ border: 'none', background: 'none', padding: 0 }}
                            >
                                <span>
                                    {open ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={16} viewBox="0 0 20 16">
                                            <g id="Group_7717" data-name="Group 7717" transform="translate(215.381 -11.085)">
                                                <g id="Group_7854" data-name="Group 7854" transform="translate(-215.381 1.085)">
                                                    <rect id="Rectangle_1027" data-name="Rectangle 1027" width={20} height={2} rx={1} transform="translate(0 10)" fill="#000" />
                                                    <rect id="Rectangle_1028" data-name="Rectangle 1028" width={16} height={2} rx={1} transform="translate(5 17)" fill="#000" />
                                                    <rect id="Rectangle_1029" data-name="Rectangle 1029" width={20} height={2} rx={1} transform="translate(0 24)" fill="#000" />
                                                </g>
                                            </g>
                                        </svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={16} viewBox="0 0 20 16">
                                            <g id="Group_7717" data-name="Group 7717" transform="translate(215.381 -11.085)">
                                                <g id="Group_7854" data-name="Group 7854" transform="translate(-215.381 1.085)">
                                                    <rect id="Rectangle_1027" data-name="Rectangle 1027" width={20} height={2} rx={1} transform="translate(0 10)" fill="#000" />
                                                    <rect id="Rectangle_1028" data-name="Rectangle 1028" width={16} height={2} rx={1} transform="translate(5 17)" fill="#000" />
                                                    <rect id="Rectangle_1029" data-name="Rectangle 1029" width={20} height={2} rx={1} transform="translate(0 24)" fill="#000" />
                                                </g>
                                            </g>
                                        </svg>
                                    )}
                                </span>
                            </button>
                            <Collapse in={open}>
                                <div className="dropdown-menu megamenu" role="menu">
                                    <div className="row g-3">
                                        <div className="col-lg-3 col-6">
                                            <div className="col-megamenu">
                                                <h6 className="title">Title Menu One</h6>
                                                <ul className="list-unstyled">
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-6">
                                            <div className="col-megamenu">
                                                <h6 className="title">Title Menu One</h6>
                                                <ul className="list-unstyled">
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-6">
                                            <div className="col-megamenu">
                                                <h6 className="title">Title Menu One</h6>
                                                <ul className="list-unstyled">
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-6">
                                            <div className="col-megamenu">
                                                <h6 className="title">Title Menu One</h6>
                                                <ul className="list-unstyled">
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-6">
                                            <div className="col-megamenu">
                                                <h6 className="title">Title Menu One</h6>
                                                <ul className="list-unstyled">
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                        </li> */}
                        {/* <li className="nav-item dropdown has-megamenu" style={{ listStyle: 'none', }}>
                            <button className="nav-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-controls="navbarCollapse" aria-expanded="true" aria-label="Toggle navigation" style={{border:'none'}}>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={16} viewBox="0 0 20 16">
                                        <g id="Group_7717" data-name="Group 7717" transform="translate(215.381 -11.085)">
                                            <g id="Group_7854" data-name="Group 7854" transform="translate(-215.381 1.085)">
                                                <rect id="Rectangle_1027" data-name="Rectangle 1027" width={20} height={2} rx={1} transform="translate(0 10)" fill="#000" />
                                                <rect id="Rectangle_1028" data-name="Rectangle 1028" width={16} height={2} rx={1} transform="translate(5 17)" fill="#000" />
                                                <rect id="Rectangle_1029" data-name="Rectangle 1029" width={20} height={2} rx={1} transform="translate(0 24)" fill="#000" />
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                            </button>
                            <div className="dropdown-menu megamenu" role="menu">
                                <div className="row g-3">
                                    <div className="col-lg-3 col-6">
                                        <div className="col-megamenu">
                                            <h6 className="title">Title Menu One</h6>
                                            <ul className="list-unstyled">
                                            </ul>
                                        </div>  
                                    </div>
                                    <div className="col-lg-3 col-6">
                                        <div className="col-megamenu">
                                            <h6 className="title">Title Menu Two</h6>
                                            <ul className="list-unstyled">
                                            </ul>
                                        </div>  
                                    </div>
                                    <div className="col-lg-3 col-6">
                                        <div className="col-megamenu">
                                            <h6 className="title">Title Menu Three</h6>
                                            <ul className="list-unstyled">
                                            </ul>
                                        </div>  
                                    </div>
                                    <div className="col-lg-3 col-6">
                                        <div className="col-megamenu">
                                            <h6 className="title">Title Menu Four</h6>
                                            <ul className="list-unstyled">
                                                <li><a href="#">Custom Menu</a></li>
                                                <li><a href="#">Custom Menu</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li> */}
                    </div>
                </div>
            </nav >
            {/* <div className="top-header bg-white" >
                <div className="header-menu">
                    <div className="header-logo">
                        <div className="container d-flex justify-content-between align-items-center">
                            <div className="logo">
                                <img src="/images/logo.png" />
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <Link to={'/'} className={`${page === '/' && "active"}`}>home</Link>
                        <Link to={'/about'} className={`${page === '/about' && "active"}`}>about</Link>
                        <Link to={'/horoscope/aries'} className={`${horoscope === 'horoscope' && "active"}`}>horoscope</Link>
                        <Link to={'#'} className={`${page === '/services' && "active"}`}>services</Link>
                        <Link to={'/plans'} className={`${page === '/plans' && "active"}`}>plans</Link>
                        <Link to={'/kundali'} className={`${page === '/kundali' && "active"}`}>kundali</Link>
                        <Link to={'/astrologers'} className={`${page === '/astrologers' && "active"}`}>astrologers</Link>
                        <Link to={'/astromall'} className={`${astromall === 'astromall' || page === '/astromall' && "active"}`}>astromall</Link>
                        {/* <Link to={'#'} className={`${page === '/blog' && "active"}`}>blog</Link> */}
            {/* </div>/ */}

            {/* <div className="logo">
                        <button className='c-btn' onClick={() => setLoginModal(true)}>sign in / sign up</button>
                    </div> */}
            {/* <div className="header-logo">
                    <div className="container d-flex justify-content-between align-items-center">
                        <div className="logo">
                            <img src="/images/logo.png" />
                        </div>
                        <div className="logo">
                            <button className='c-btn' onClick={() => setLoginModal(true)}>sign in / sign up</button>
                        </div>
                    </div>
                </div> */}
            <div className="fixed-icons bs">
                <Link to={'/astrologers'} data-bs-toggle="tooltip" data-bs-placement="top" title="Chat With Astrologers"><img src="/images/phone-call.png" className='img-fluid' /></Link>
                <Link to={'/astrologers'}><img src="/images/chat.png" className='img-fluid' /></Link>
            </div>
            {
                loginModal &&
                <div id="c-modal">
                    <div className="c-modal-body py-3">
                        <div className="c-modal-header d-flex justify-content-between px-2">
                            <h4>Login</h4>
                            <h4 onClick={() => setLoginModal(false)} style={{ cursor: "pointer" }}><i className='fa fa-times'></i></h4>
                        </div>
                        <hr className='mt-0' />
                        <div className='text-center'>
                            <span >You will receive a 6 digit code for verification</span>
                            <br />
                            <br />
                            {/* <span >OTP : 123456</span> */}

                        </div>
                        <div className="conatiner">
                            <div className="contact-input-box mt-3">
                                <PhoneInput
                                    name='contact'
                                    country={"in"}
                                    enableSearch={true}
                                    value={phone}
                                    onChange={(phone) => setPhone(phone)}
                                    disableCountryCode={true}
                                    placeholder='Enter Mobile Number'
                                    disableCountryGuess={true}

                                />
                                <button className='c-btn w-100 mt-3' onClick={() => { loginApi() }}  >SEND OTP</button>
                                {/* <button className='c-btn w-100 mt-3' onClick={() => { loginApi() }}  >SEND OTP</button> */}

                                {otpStatus && (
                                    <div>
                                        <span >Enter 6 digit code </span><br />
                                        <OTPInput
                                            value={enteredOtp}
                                            onChange={setenteredOtp}
                                            numInputs={6}
                                            separator={<span>--</span>}
                                            inputStyle={{ width: '3.5rem', height: '3.5rem' ,margin:'0px 5px'}}
                                            isInputNum
                                            renderInput={(props) => <input {...props} />}
                                        />
                                        <br />
                                        <button className='c-btn w-100 mt-3' disabled={!expireOtp} onClick={OtpVerify} >Verify OTP</button>
                                        <div>
                                            <div style={{ fontSize: '15px' }}>
                                                <label>Otp Expire <span id="timeRemain">{min + ':' + sec}</span> </label>
                                            </div>
                                            <div style={{ fontSize: '10px', borderRadius: '5px' }}>
                                                {
                                                    !expireOtp ?
                                                        <button className='c-btn w-100 mt-3' onClick={() => { Resendotp(); }}>Resend Otp</button>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                </div>
            }

            {
                LogOutStatus &&
                <div id="c-modal">
                    <div className="c-modal-body py-3 ">
                        <div className="c-modal-header d-flex justify-content-between px-2 bb">
                            <h5 className='text-danger'>Logout</h5>
                            <h4 onClick={() => SetLogOutStatus(false)} style={{ cursor: "pointer" }}><i className='fa fa-times'></i></h4>
                        </div>
                        <h4 className='text-center mt-2 text-bold'>Are You Want to Logout ?</h4>
                        <div className='col-12 text-end mt-4'>
                            <button type='button' className='c-btn mx-2' onClick={() => { logout(); SetLogOutStatus(false) }}>LogOut</button>
                            <button className='c-btn ' onClick={() => { SetLogOutStatus(false) }}>Cancel</button>
                        </div>

                    </div>
                </div>
            }
            <ToastContainer />
        </>
    )
}

export default Header