import React from 'react'
import { Link } from 'react-router-dom'
import PageBanner from '../../Utility/PageBanner'

const About = () => {
    return (
        <>
            
  <div class="about_us_banner">
    <img class="img-fluid" src="{{asset('front/images/logos/about.png')}}" alt=""/>
   
    <div class="container" >
      <div class="container-fluid topAbout" id='form-innner'>
        <div class="row">
          <div class="col-xl-4 text-center font-weight-bold borderRight">
            <h1 style={{fontsize:  '3.5rem'}}><i class="fa fa-user"></i><span class="pl-2">5000</span><span style={{verticalAlign: 'super', fontsize: '30px'}}>+</span></h1>
            <h4 style={{color: '#ff5722'}}>Happy Clients </h4>
          </div>

          <div class="col-xl-4 text-center font-weight-bold borderRight">
            <h1 style={{fontsize: '3.5rem'}}><i class="fa fa-user-circle-o"></i><span class="pl-2">20</span>  <span style={{verticalalign: 'super' , fontsize: '30px'}}>+</span> </h1>
            <h4 style={{color: '#ff5722'}}>Verified Astrologers </h4>
          </div>

          <div class="col-xl-4 text-center font-weight-bold">
            <h1 style={{fontsize: '3.5rem'}}><i class="fa fa-building"></i><span class="pl-2">10</span> <span style={{verticalalign: 'super' , fontsize: '30px'}}>+</span> </h1>
            <h4 style={{color: '#ff5722'}}>Countries </h4>
          </div>
        </div>     
      </div>
    </div>


    <div class="container mt-3 pb-5">
      <div class="about-us">
        <div class="row mt-4 pt-2">  
          <div class="col-md-12">
            {/* <h4 class="h1 text-center">OUR STORIES</h1> */}
            <h4 class="h1 text-center" >OUR STORIES</h4>
            <div class="content-about">
              <p class="py-2 px-4">AstroCall came into being with an aim to not only keep the ethos of traditional astrology intact but to also amalgamate in it the solutions to modern problems like mental health, stress, depression, etc. We are working to build the world’s largest community of spiritual and well-being experts, and we hope you become a part of this journey one prediction at a time.</p>
          </div>
        </div>  
        </div> 
      </div>

      {/* <!-- 1st --> */}
      <div class="row mt-5 pt-2" style={{background:'#eee'}}>  
        <div class="col-md-12">
          <h4 class="h1 text-center">WHO WE ARE</h4>
          <div class="content-about">
            <p class="py-2 px-4">AstroCall brings to you the best astrology website and app for online Astrology predictions. Our dynamic services range from Kundli matching to Kundli predictions. At AstroCall, our visitors get the best future predictions across aspects such as Marriage, Love, Career or Health, and more. When using AstroCall, the best astrology site in India, you can trust us for sharing very accurate and well-researched astrological content that would offset most of your worries.</p>
          </div>
        </div>   
      </div>

      {/* <!-- 2nd --> */}
      <div class="row about-row mt-5 pt-2">
        <div class="col-md-12">
          <h4 class="h1 text-center">WHY ASTROCALL</h4>
          <div class="content-about">
            <p class="py-2 px-4">AstroCall is an excellent online astrology app. Talk to our prolific astrologer on the phone and find a solution to all your worries by looking at your future. With AstroCall, you can access accurate astrological predictions from leading astrologers in India. Get the best predictions related to marriage, love life, or work via a phone call, face-to-face conversation, video call, or report.<br />
            </p>
          </div>
        </div>
      </div>  
      

      <div class="row about-row mt-5 pt-2" style={{background:'#eee'}}>
        <div class="col-md-12">
          <h4 class="h1 text-center">BENEFITS OF ASTROCALL APPLICATION</h4>
          <div class="content-about">
            <p class="py-2 px-4">
              <li class="px-4" style={{fontweight:'bold' , fontsize:'18px'}}>The mobile application could resolve all sorts of queries as they could find one of the best astrologers.</li>
              <li class="px-4" style={{fontweight:'bold' , fontsize:'18px'}}>Through audio and chat facilities, astrologers could predict by looking towards Kundli and would try to find the best possible solution. </li>
              <li class="px-4" style={{fontweight:'bold' , fontsize:'18px'}}>The application is transparent and secured as it keeps all types of information confidential.</li>
              <li class="px-4" style={{fontweight:'bold' , fontsize:'18px'}}>It renders 24*7 support services. </li>
            </p>
            <p class="py-2 px-4">
              Therefore, the AstroCall application is designed in such a manner that users could get all answers related to their love life, career, health over the chat, audio call, or through a report in a presentable manner. 
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
        </>
    )
}
export default About
