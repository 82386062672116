import React from 'react';

const KundliDetails = () => {
    
    return (
        <div>
            <div className="kundli_details">
                <div className="container">
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button className="nav-link active" id="nav-panchang-tab" data-bs-toggle="tab" data-bs-target="#nav-panchang" type="button" role="tab" aria-controls="nav-panchang" aria-selected="true">
                                nakshatra details
                            </button>
                            <button className="nav-link" id="nav-choughariya-tab" data-bs-toggle="tab" data-bs-target="#nav-choughariya" type="button" role="tab" aria-controls="nav-choughariya" aria-selected="false">
                                Choughariya Details
                            </button>
                            <button className="nav-link" id="nav-divisional-tab" data-bs-toggle="tab" data-bs-target="#nav-divisional" type="button" role="tab" aria-controls="nav-divisional" aria-selected="false">
                                Divisional Chart
                            </button>
                            <button className="nav-link" id="nav-yoga-tab" data-bs-toggle="tab" data-bs-target="#nav-yoga" type="button" role="tab" aria-controls="nav-yoga" aria-selected="false">
                                Yoga Details
                            </button>
                        </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-panchang" role="tabpanel" aria-labelledby="nav-panchang-tab">
                            <table class="table table-bordered mb-5 table-hover">
                                <tbody><tr class="bg-secondary text-white text-center"><th colspan="2&quot;">Nakshatra Details</th></tr>
                                    {/* <tr><th>Nakshatra</th><td>Rohini</td></tr>
                                    <tr><th>Nakshatra Lord</th><td>Chandra (Moon)</td></tr>
                                    <tr><th>Chandra Rasi</th><td>Vrishabha</td></tr>
                                    <tr><th>Chandra Rasi Lord</th><td>Shukra (Venus)</td></tr>
                                    <tr><th>Soorya Rasi</th><td>Simha</td></tr>
                                    <tr><th>Soorya Rasi Lord</th><td>Ravi (Sun)</td></tr>
                                    <tr><th>Zodiac</th><td>Virgo</td></tr> */}
                                    <tr class="bg-secondary text-white text-center"><th colspan="2&quot;">Additional Info</th></tr>
                                    <tr><th>Deity</th><td>Brahma</td></tr>
                                    <tr><th>Ganam</th><td>Manushya</td></tr>
                                    <tr><th>Symbol</th><td>Chariot (An ox cart)</td></tr>
                                    <tr><th>Animal Sign</th><td>Snake</td></tr>
                                    <tr><th>Nadi</th><td>Kapha</td></tr>
                                    <tr><th>Color</th><td>White</td></tr>
                                    <tr><th>Best Direction</th><td>East</td></tr>
                                    <tr><th>Syllables</th><td>O, Va, Vi, Vu</td></tr>
                                    <tr><th>Birth Stone</th><td>Pearl</td></tr>
                                    <tr><th>Gender</th><td>Female</td></tr>
                                    <tr><th>Planet</th><td>Chandra</td></tr>
                                    <tr><th>Enemy Yoni</th><td>Mongoose</td></tr>
                                </tbody></table>
                        </div>
                        <div className="tab-pane fade" id="nav-choughariya" role="tabpanel" aria-labelledby="nav-choughariya-tab">
                            <h1>Choughariya</h1>
                        </div>
                        <div className="tab-pane fade" id="nav-divisional" role="tabpanel" aria-labelledby="nav-divisional-tab">
                            <h1>Divisional</h1>
                        </div>
                        <div className="tab-pane fade" id="nav-yoga" role="tabpanel" aria-labelledby="nav-yoga-tab">
                            <div class="freereport">
                                <h5 class="mt-3">Major Yogas</h5>

                                <div class="text-left">
                                    <div class="">
                                        <h6>Major Yogas</h6>
                                        <p>Your kundli has 3 major yogas</p>
                                    </div>
                                    <div class="">
                                        <h6>Chandra Yogas</h6>
                                        <p>Your kundli does not have any chandra yoga.</p>
                                    </div>
                                    <div class="">
                                        <h6>Soorya Yogas</h6>
                                        <p>Your kundli has 1 soorya yogas.</p>
                                    </div>
                                    <div class="">
                                        <h6>Inauspicious Yogas</h6>
                                        <p>Your kundli does not have any inauspicious yoga.</p>
                                    </div>
                                </div>
                                <div class="alert alert-message ">The person is Not Manglik</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default KundliDetails;
