import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Gemstone = () => {

    const [GemstoneData, SetGemstoneData] = useState([])
    // console.log(GemstoneData.slice(9, 18), 'GemstoneData')
    const [currentPage, setCurrentPage] = useState(1)
    const [searchVal, setSearchVal] = useState("");

    const itemsPerPage = 9

    const get_gemstone = async () => {
        try {
            const res = await axios.get('https://astrocall.live/api/gemstone');
            if (res) {
                SetGemstoneData(res?.data?.data.filter((item) => item));
                // console.log(res?.data?.data.filter((item) => item),'asdasd')
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        get_gemstone()
    }, [])

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = GemstoneData.slice(indexOfFirstItem, indexOfLastItem);
    //  console.log(currentItems,'currentitems')

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(GemstoneData.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    const handleClick = (number) => {
        setCurrentPage(number);
    }

    const filteredData = GemstoneData.filter((item) => item &&
        item.name.toLowerCase().includes(searchVal.toLowerCase())
    );

    // console.log(filteredData, 'filteredData')

    const handleSearchChange = (e) => {
        // console.log('click')
        setSearchVal(e.target.value);
    };

    const handleSearchClick = (e) => {
        e.preventDefault();
    };

    return (
        <>
            <div className="container ">
                <div className="row g-3">
                    {/* <div className="col-8 d-none d-md-block"> */}
                    <form className="d-flex" onSubmit={handleSearchClick}>
                        <div className="input-group mb-3">
                            <input
                                type="search"
                                id="search"
                                name='search'
                                className="form-control"
                                placeholder="Search"
                                value={searchVal}
                                onChange={handleSearchChange}
                            />
                            <div className="input-group-append">
                                <button
                                    className="btn astro_search_btn"
                                    style={{ border: '1px solid #ff5722', color: '#ff5722' }}
                                    type="submit"
                                >
                                    <i className="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                    {/* </div> */}
                    {filteredData?.length > 0 ? (
                        filteredData?.map(item => (
                            <div key={item.id} className="col-12 col-sm-6 col-md-6 col-lg-4">
                                <div className="bs">
                                    <Link to='#'>
                                        <div className="astro-box">
                                            {/* <div className="img-box">
                                                <img src={item?.image} />
                                                <input type="hidden" value="1" id={item?.category_id} />
                                            </div> */}
                                            <div class="img-circle">
                                                <div class="col-md-5" id="border-right">
                                                    <img src={item?.image} />
                                                    <input type="hidden" value="1" id={item?.category_id} />
                                                </div>
                                            </div>



                                            <div className="text ms-3">
                                                <h6 className="m-0 p-0">{item?.name}</h6>
                                                <span>{item.sub_title}</span><br />
                                                <span style={{ fontsize: '20px', color: '#333', fontweight: 'bold' }}>
                                                    {item?.price}/-
                                                </span>
                                                <del><span className="text-danger"></span></del>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="mt-3 text-end col-12">
                                        <Link to={`/ProductDetails?PR=${item.slug}`} className="c-btn text-white">
                                            View Details
                                        </Link>
                                        <Link to={`/ProductBuynow?PR=${item.slug}`} className="c-btn text-white mx-2">
                                            Buy Now
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No gemstones available.</p>
                    )}
                </div>
            </div>
            <div className="pagination justify-content-end mt-3 col-md-12">
                <nav>
                    <ul className="pagination">
                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => handleClick(currentPage - 1)} aria-label="« Previous">‹</button>
                        </li>
                        {pageNumbers?.map(number => (
                            <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                                <button onClick={() => handleClick(number)} className="page-link">
                                    {number}
                                </button>
                            </li>
                        ))}
                        <li className={`page-item ${currentPage === pageNumbers.length ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => handleClick(currentPage + 1)} aria-label="Next »">›</button>
                        </li>
                    </ul>
                </nav>
                {/* <nav>
                    <ul class="pagination">
                        <li class="page-item">
                            <a class="page-link" href="#" rel="prev" aria-label="« Previous">‹</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">1</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#" >2</a>
                            <button class="page-link" >2</button>
                        </li> 
                        <li class="page-item">
                            <a class="page-link" href="#">3</a>
                        </li>
                        <li class="page-item active" aria-current="page"><span class="page-link">3</span></li>
                        <li class="page-item">
                            <a class="page-link" href="#">4</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#" rel="next" aria-label="Next »">›</a>
                        </li>
                    </ul>
                </nav> */}
            </div>
        </>
    )
}
export default Gemstone