import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import LoaderCom from './LoaderCom'
import { CommonContextData } from '../../Context/CommonContext'

const HoroscopeTab = () => {
    const {getDataFun, dataList, loading} = useContext(CommonContextData);
    const navigate = useNavigate()
    const horoscopeClick = (page) => {
        navigate(`/horoscope/${page}`)
    }
    useEffect(() => {
        getDataFun("/zodiac");
    }, [])


    return (
        <>
            <div className="row bs m-0 px-2 pt-0 g-3 text-center">
                {
                    loading ?
                        <>
                            <LoaderCom type="spokes" color="#bd0001" />
                        </>
                        :
                        <>
                            {
                                dataList?.map((data, i) => (
                                    <div className="col-4 col-sm-4 col-md-2 col-lg-1">
                                        <div className="bs">
                                            <div className="content text-center" onClick={() => horoscopeClick(data?.name.toLowerCase())}>
                                                <img src={data.sign_img} className='img-fluid' />
                                                <span style={{ fontSize: '12px' }}>{data.hindiName}</span>
                                                <span style={{ fontSize: '14px' }}>{data.name}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </>
                }
            </div>
        </>
    )
}

export default HoroscopeTab