import axios from "axios";
import React, { useEffect, useState } from "react";
import { toastifySucces, toastifySuccess } from "../../Utility/Utility";
const Contact = () => {
	const [Massage, SetMassage] = useState(false)
	const [Value, setValue] = useState({
		name: '',
		email: '',
		phone: '',
		city: '',
		message: '',
	})

	const Handlechange = (e) => {
		setValue({
			...Value,
			[e.target.name]: e.target.value
		})
	}

	const ConatctUS = async (e) => {
		const { name, email, phone, city, message } = Value;
		const val = { 'name': name, 'email': email, 'phone': phone, 'city': city, 'message': message }
		try {
			const res = await axios.post('https://astrocall.live/api/v1/create', val);
			console.log(res.data.message, 'res')
			if (res) {
				toastifySuccess(res.data.message)
				// setValue({
				// 	...Value,
				// 	name: '',
				// 	email: '',
				// 	phone: '',
				// 	city: '',
				// 	message: '',
				// })
				SetMassage(false)
			}
		} catch (error) {
			console.error('Error:', error);
		}
	}


	// useEffect(() => {
	//     // Fetch CSRF token from the server
	//     const fetchCsrfToken = async () => {
	//         try {
	//             const response = await axios.get("http://127.0.0.1:8000/csrf-token");
	//             setCsrfToken(response.data.csrfToken);
	//         } catch (error) {
	//             console.error("Error fetching CSRF token", error);
	//         }
	//     };


	//     fetchCsrfToken();
	// }, []);
	// const handleChange = (e) => {
	//     setFormData({ ...formData, [e.target.name]: e.target.value });
	// };
	// const handleSubmit = async (e) => {
	//     e.preventDefault();

	//     if (!csrfToken) {
	//         console.error("CSRF token not found.");
	//         return;
	//     }

	//     try {
	//         const response = await axios.post("http://127.0.0.1:8000/api/create", formData, {
	//             headers: {
	//                 "Content-Type": "application/json",
	//                 "X-CSRF-TOKEN": csrfToken
	//             }
	//         });
	//         console.log(response);
	//         // Handle success, show a message or redirect
	//     } catch (error) {
	//         console.error("Error submitting form", error);
	//         // Handle error, show a message or log errors
	//     }
	// };

	return (
		<>

			{/* <div className="container">
				<header>
					<h1>Contact Us</h1>
				</header>

				<div className="contactus">
					<h2>Get in Touch</h2>
					<div className="row mt-4"></div>
					<div className="row">
						<div className="col-xl-6">
							<div id="map">
								<iframe
									src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.538285745908!2d75.76456411451936!3d26.854633969053978!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db4fbf1801bd1%3A0xaa455bfc5250efb5!2sArustu%20Technology%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1616665214774!5m2!1sen!2sin"
									width="100%"
									height="350"
									frameborder="0"
									style={{ border: '0' }}
									allowfullscreen=""
									aria-hidden="false"
									tabindex="0"
								/>
							</div>
						</div>
						<div className="col-xl-6">
							<div className="contactForm">
								<h3>Feel Free To Contact</h3>
								<form onSubmit={handleSubmit}>
									<div className="row">
										<div className="col-xl-6">
											<div className="form-group">
												<label>Name</label>
												<input
													type="text"
													name="name"
													placeholder="Name"
													className="form-control"
													value={formData.name}
													onChange={handleChange}
												/>
											</div>
										</div>
										<div className="col-xl-6">
											<div className="form-group">
												<label>Email</label>
												<input
													type="email"
													name="email"
													placeholder="Email"
													className="form-control"
													value={formData.email}
													onChange={handleChange}
												/>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-xl-6">
											<div className="form-group">
												<label>Phone</label>
												<input
													type="text"
													maxlength="10"
													name="phone"
													placeholder="Phone"
													className="form-control"
													value={formData.phone}
													onChange={handleChange}
												/>
											</div>
										</div>
										<div className="col-xl-6">
											<div className="form-group">
												<label>City</label>
												<input
													type="text"
													name="city"
													placeholder="City"
													className="form-control"
													value={formData.city}
													onChange={handleChange}
												/>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-xl-12">
											<div className="form-group">
												<label htmlFor="">Message</label>
												<textarea
													name="message"
													placeholder="Enter message..."
													rows="3"
													className="form-control textarea"
													value={formData.message}
													onChange={handleChange}
												/>
											</div>
										</div>
									</div>
									<center>
										<button type="submit" className="">
											Submit
										</button>
									</center>
								</form>
								{msg && <p>{msg}</p>}
							</div>
						</div>
					</div>
				</div>
			</div> */}
			<div className="container mt-4">
				<div className="col-12">
					<div className="row">
						<div className="col-xl-6 mt-4">
							<div id="map">
								<iframe
									src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.538285745908!2d75.76456411451936!3d26.854633969053978!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db4fbf1801bd1%3A0xaa455bfc5250efb5!2sArustu%20Technology%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1616665214774!5m2!1sen!2sin"
									width="100%"
									height="350"
									frameborder="0"
									style={{ border: '0' }}
									allowfullscreen=""
									aria-hidden="false"
									tabindex="0"
								/>
							</div>
						</div>

						<div className="col-6">
							<div className="contact">
								<div className="heading">Contact Us</div>
								<form className="form ">
									<div className="row">
										<div className="col-xl-6">
											<div className="form-group">
												<input
													type="text"
													name="name"
													placeholder="Name"
													className="form-control input"
													value={Value.name}
													onChange={Handlechange}
												/>
											</div>
										</div>
										<div className="col-xl-6">
											<div className="form-group">
												<input
													type="email"
													name="email"
													placeholder="Email"
													className="form-control input"
													value={Value.email}
													onChange={Handlechange}
												/>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-xl-6">
											<div className="form-group">
												<input
													type="text"
													maxlength="10"
													name="phone"
													placeholder="Phone"
													className="form-control input"
													value={Value.phone}
													onChange={Handlechange}
												/>
											</div>
										</div>
										<div className="col-xl-6">
											<div className="form-group">
												<input
													type="text"
													name="city"
													placeholder="City"
													className="form-control input"
													value={Value.city}
													onChange={Handlechange}
												/>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-xl-12">
											<div className="form-group">
												<textarea
													name="message"
													placeholder="Enter message..."
													rows="3"
													className="form-control textarea input"
													value={Value.message}
													onChange={Handlechange}
												/>
											</div>
										</div>
									</div>
								</form>
								<button className="login-button" onClick={() => { ConatctUS() }}>
									Submit
								</button>
								{/* <div className="social-account-container">
									<span className="title">Or Sign in with</span>
									<div className="social-accounts">
										<button className="social-button google">
											<svg className="svg" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 488 512">
												<path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
											</svg></button>
										<button className="social-button apple">
											<svg className="svg" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
												<path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" />
											</svg>
										</button>
										<button className="social-button twitter">
											<svg className="svg" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
												<path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
											</svg>
										</button>
									</div>
								</div> */}
							</div>
						</div>
					</div>

				</div>
			</div>


		</>
	);
};

export default Contact;
