import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';

const ProductDetails = () => {

  const useQuery = () => {
    const params = new URLSearchParams(useLocation().search);
    return {
      get: (param) => params.get(param)
    };
  };

  const query = useQuery();
  var slugdata = query?.get("PR");

  const [filterslugdata, setfilterslugdata] = useState()

  useEffect(() => {
    get_gemstone()
  }, [])

  const get_gemstone = async () => {
    try {
      const res = await axios.get('https://astrocall.live/api/gemstone');
      if (res) {
        setfilterslugdata(res?.data?.data?.filter((item) => item?.slug === slugdata))
      }
    } catch (error) {
      console.log(error)
    }
  }


  // const loadRazorpay = async () => {
  //   var options = {
  //     "key": "rzp_live_zWtXPYpzqDLpAH",
  //     "amount": (filterslugdata ? filterslugdata[0]?.price : '') * 100,
  //     // "currency": "INR", 
  //     // "name": "NiceSnippets",
  //     // "description": "Test Transaction",
  //     // "image": "https://www.nicesnippets.com/image/imgpsh_fullsize.png",
  //     // "order_id": ordersdata.id,
  //     handler: async function (response) {
  //     },
  //   }
  //   const rzp1 = new window.Razorpay(options);
  //   rzp1.open();
  // }

  const loadRazorpay = async () => {
    if (!window.Razorpay) {
      console.error('Razorpay SDK not loaded');
      return;
    }
    var options = {
      "key": "rzp_live_zWtXPYpzqDLpAH",
      "amount": (filterslugdata ? filterslugdata[0]?.price : '') * 100,
      handler: async function (response) {
        console.log(response);
      },
    }
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  }



  return (
    <div class="detail_box pt-5">
      {filterslugdata && filterslugdata.length > 0 ? (
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="row box">
              <div class="col-md-5">
                <div class="left">
                  <img src={filterslugdata ? filterslugdata[0]?.image : ''} />
                </div>
              </div>
              <div class="col-md-7 section-profile">
                <div class="right">
                  <h3> {filterslugdata ? filterslugdata[0]?.name : ''} </h3>
                  <h6 class="ratti">{filterslugdata ? filterslugdata[0]?.sub_title : ''}
                    <span style={{ fontsize: '22px', fontweight: 'bold' }}>
                      <img src="" alt="" style={{ width: '15px', height: ' 20px', borderradius: '0px', paddingbottom: '4px' }} />
                      {filterslugdata ? filterslugdata[0]?.price : ''} /-
                    </span>
                  </h6>
                  <p style={{ textalign: 'justify' }}>
                    {filterslugdata ? filterslugdata[0]?.description : ''}
                  </p>
                  <div class="button col-12 mt-2 text-end">
                    <a href="/gemstone" class="c-btn mx-2"><i class="fa fa-chevron-circle-left" ></i>&nbsp; Back</a>
                    <input type="submit" value="Buy Now" class="c-btn " onClick={() => { loadRazorpay() }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>Loading...</p> 
      )}
    </div>
  );

}

export default ProductDetails