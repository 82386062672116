import React, { useContext, useState } from 'react'
import { CommonContextData } from '../../../Context/CommonContext';
import profile from '../../../Horoimg/Horos/profile.png'

const Profile = () => {

  const str = JSON.parse(sessionStorage.getItem('UserProfile'));
  const [userdataprofile, SetUserdataprofile] = useState(str?.data?.data)
  // SetUserdataprofile(str?.data?.data)
  // console.log(userdataprofile,'userdataprofile')

  const { userdata, setuserdata } = useContext(CommonContextData);

  return (
    <>
     <section className='banner'>
                <div className="container-fluid">
                    <div className="page-img-banner">
                        <img src={profile} className='img-fluid' alt="banner" />
                    </div>
                </div>
            </section>
    <div class="container ">
      <div class="row ">
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <section className='section-profile px-1'>
            <div>
              <div >
                <div class="account-settings">
                  <div class="user-profile bb">
                    <div class="user-avatar ">
                      <center>
                        {/* <h3 class="text-uppercase" style={{ width: '75px', height: '75px', textalign: 'center', border: '1px solid #ff5622', borderradius: '100%', color: '#ff5722', fontsize: '44px', lineheight: '70px', fontfamily: 'sans-serif', }}>B</h3> */}
                      </center>
                    </div>
                    <h4 class="user-name text-danger"> ₹ {userdataprofile?.amount} </h4>
                    <h4 class="user-name">{userdataprofile?.name}</h4>
                    <h6 class="user-email">{userdataprofile?.email}</h6>
                    <h6 class="user-email">{userdataprofile?.contact}</h6>
                  </div>
                  <div class="about mt-2 bb">
                    <h5 class="mb-2" style={{ color: '#3b476a' }}>Birth Of Date</h5>
                    <p style={{ fontweight: '500', fontsize: '25px' }}> {userdataprofile?.tob}</p>
                  </div>
                  <div class="about pt-3 bb">
                    <h5 class="mb-2" style={{ color: '#3b476a' }}>About</h5>
                    <p>{userdataprofile?.gender}</p>
                  </div>
                  <div class="about bb">
                    <h5 class="mb-2" style={{ color: '#3b476a' }}>Address</h5>
                    <p>{userdataprofile?.pob},{userdataprofile?.state}</p>
                  </div>
                  <div class="about pt-4">
                    <a href="" class="btn btn-primary">
                      Change Request
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
          <section className='section-profile px-1'>
            <form method="POST"  >
              <input type="hidden" />
              <div className="col-12">
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <h6 class="mb-3 text-primary" >Personal Details</h6>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div class="form-group">
                      <label for="fullName">Full Name</label>
                      <input type="hidden" name="id" id="txtPassportNumber" value={userdataprofile?.id} />
                      <input type="hidden" name="user_id" id="txtPassportNumber" value=""
                      />
                      <input type="text" class="form-control" name="name" id="txtPassportNumber" value={userdataprofile?.name} placeholder="Enter full name" />
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div class="form-group">
                      <label for="eMail">Email</label>
                      <input type="email" class="form-control" name="email" id="eMail" value={userdataprofile?.email} placeholder="Enter email ID" />
                    </div>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-4 col-sm-4 col-12 ">
                    <div class="form-group">
                      <label for="photo" className='mt-2'>Profile Photo</label>
                      <input type="file" id="profile" name="profile" accept="image/png, image/jpeg" className='form-control' />
                    </div>
                  </div>
                  <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                    <div class="form-group">

                      {/* <h3 class="text-uppercase" style={{ width: '75px', height: '75px', textalign: 'center', border: '1px solid #ff5622', borderradius: '100%', color: '#ff5722', fontsize: '44px', lineheight: '70px', fontfamily: 'sans-serif', }}>B</h3> */}
                    </div>
                  </div>
                </div>

              </div>
            </form>
            <div class="row " >
              {/* <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h6 class="mb-3 text-primary">Address</h6>
              </div> */}
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group mt-2">
                  <label for="Street">Address</label>
                  <input type="text" name="pob" class="form-control" value={userdataprofile?.pob} id="pob" placeholder="Enter Street" />
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group mt-2">
                  <label for="sTate">State</label>
                  <input type="text" name="state" class="form-control" value={userdataprofile?.state} id="state" placeholder="Enter State" />
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="form-group mt-2">
                  <label for="zIp">Occuption</label>
                  <input type="text" name="occuption" class="form-control" value={userdataprofile?.occuption} id="occuption" placeholder="Occuption" />
                </div>
              </div>
            </div>
            <div class="about pt-4 ml-4 mb-4">
              <button class="btn btn-success btn-sm">
                Update Detail
              </button>
            </div>
          </section>

        </div>
      </div>
    </div >
    </>
  )
}

export default Profile